import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';

import CampaignsToolbar from './toolbar';
import { fetchCampaignsList, saveCampaignGeneral } from '../../actions/campaigns';
import { fetchRetailersGroups, fetchRetailersList } from '../../actions/retailers';
import { fetchSubscribersGroups } from '../../actions/subscribers';

import { setSectionOptions } from '../../actions/chrome';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { Base64 } from 'js-base64';

import { checkPermission } from '../../helpers/permissions';

var formName = 'formCampaignsSetup';

class CampaignsSetup extends Component {

	constructor(props){
		super(props);
						
		const { id } = this.props.match.params;
		
        if(id){
	       	// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
				this.props.history.push('/');
			}
			
			this.props.fetchCampaignsList(id, () => {
		       	
		       	if(!this.props.campaigns.campaigns[id]){
					this.props.history.push('/campaigns');
				}else{
					this.updateDomElements();
				}
	       	});
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_ADD')){
				this.props.history.push('/');
			}
		}
				
		let groups = checkPermission(this.props.account.permissions, id ? 'CAMPAIGNS_EDIT' : 'CAMPAIGNS_ADD', 'groups');
		
		if(groups){
			this.props.fetchRetailersGroups();
		}	
		
		let subscribers = checkPermission(this.props.account.permissions, id ? 'CAMPAIGNS_EDIT' : 'CAMPAIGNS_ADD', 'subscribers');
		
		if(subscribers){
			this.props.fetchSubscribersGroups(false, () => {
				this.updateDomElements();
			});
		}	
		
		let channels = checkPermission(this.props.account.permissions, id ? 'CAMPAIGNS_EDIT' : 'CAMPAIGNS_ADD', 'social_channels');
		
		if(channels){
			this.props.fetchRetailersList(this.props.account.group.id);
		}	
		
		moment.locale(i18n.language);
		this.props.stopSubmission(formName);
		
		const startDate = moment.tz(this.props.account.profile.timezone).add(1, 'hour').toDate();
		const endDate = moment.tz(this.props.account.profile.timezone).add(1, 'hour').add(1, 'month');
		
		this.state = {
            id: id,
            type: false, 
            date: {
	            startDate: startDate,
	            endDate: endDate
            },
            embargo: false,
            customise: true,
            next: false,
            distribution: {
				website: true,
				email: true,
				landing: true,
				social: true
			},
			groups: [],
			sub_list: [],
			social_channels: [],
			all: true,
			permissions: {
				embargo: checkPermission(this.props.account.permissions, id ? 'CAMPAIGNS_EDIT' : 'CAMPAIGNS_ADD', 'embargo'),
				customise: checkPermission(this.props.account.permissions, id ? 'CAMPAIGNS_EDIT' : 'CAMPAIGNS_ADD', 'customise'),
				groups: groups,
				subscribers: subscribers,
				social_channels: channels
			},
			readonly: false,
			description: {
				0: ''
			},
			description_counter: 0,
			delay: false,
			deletable: true
        }    
          
        this.props.initialize({
	    	date_start: startDate,
	    	date_end: endDate
	    });	 
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements(); 
	}
	
	updateDomElements(){
		
		if(this.props.campaigns.campaigns && this.state.id){
		
			let campaign = this.props.campaigns.campaigns[this.state.id];			
			let sub_list = [];
			let social_channels = [];
			let email = _.find(campaign.distribution, {type:'email'});
			
			if(email && email.settings && email.settings.lists){
				
				_.forEach(email.settings.lists, (lists, type) => {
						
					_.forEach(lists, (list, key) => {
						sub_list.push(`${type}|${list}`);				
					});				
				});
			}
			
			let social = _.find(campaign.distribution, {type:'social'});
			
			if(social && social.settings && social.settings.channels){
				
				_.forEach(social.settings.channels, (channels, type) => {
						
					_.forEach(channels, (channel, key) => {
						social_channels.push(`${type}|${channel}`);				
					});				
				});
			}
			
			let groups = campaign.groups ? campaign.groups : [];
			let description = Object.assign({}, JSON.parse(campaign.description))
			let deletable = true;
			
			if(campaign.status === 'deactivated' || (campaign.status === 'launched' && moment(campaign.date_end.date) < moment())){
				deletable = false;
			}
			
			const date_start = moment.utc(campaign.date_start.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate();
			const date_end = moment.utc(campaign.date_end.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate();
		
			let init = {...campaign, ...{ subscribers: sub_list, groups: groups, social_channels: social_channels, date_start: date_start, date_end: date_end }};
								
			this.setState({
				type: campaign.type,
				date: {
		            startDate: date_start,
		            endDate: date_end
	            },
	            embargo: campaign.date_embargo ? moment.utc(campaign.date_embargo.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate() :  false,
	            customise: campaign.customise === 1 ? true : false,
	            distribution: {
					website: _.find(campaign.distribution, {type:'website'}) ? true : false,
					email: _.find(campaign.distribution, {type:'email'}) ? true : false,
					landing: _.find(campaign.distribution, {type:'landing'}) ? true : false,
					social: _.find(campaign.distribution, {type:'social'}) ? true : false,
				},
				all: campaign.groups ? false : true,
				groups: groups,
				readonly: campaign.status === 'draft' ? false : true,
				deletable: deletable,
				sub_list: sub_list,
				social_channels: social_channels,
				description: description,
				description_counter: Object.keys(description).length-1,
				delay: campaign.date_process ? moment.utc(campaign.date_process.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate() :  false
			});	
			
			if(Object.keys(description).length > 0){
				_.map(description, (item, key) => {
					init = {...init, ...{ [`description_${key}`]: item }};
				});				
			}
									
			this.props.setSectionOptions('campaigns', campaign.internal_name, '/campaigns');
			this.props.initialize(init);	
			
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:settings_title'), '/campaigns');
		}
		
		// DO THIS HERE TO FORCE RERENDER OF DROPDOWN
		if(this.props.subscribers.groups){
			this.setState({ subscribersList: this.props.subscribers.groups })
		}
	}
	
	onInputTypeChange(selected){
				
		this.setState({
			type: selected.value
		});
	}

	onInputDateChange(type, date){
		
		// This forces date change so ready for validation
		this.props.change(`date_${type.replace('Date', '')}`, date);
				
		let current = this.state.date;
		
		current[type] = date;
		
		this.setState({
			date: current
		});
		
		if(this.state.delay && moment(current.startDate).format('YYYY-MM-DD HH:mm:ss') < moment(this.state.delay).format('YYYY-MM-DD HH:mm:ss')){
			this.setState({
				delay: current.startDate
			});
		}
		
		if(this.state.embargo && moment(current.startDate).format('YYYY-MM-DD HH:mm:ss') < moment(this.state.embargo).format('YYYY-MM-DD HH:mm:ss')){
			this.setState({
				embargo: current.startDate
			});
		}		
	}
	
	onInputDateEmbargoChange(date){
		
		this.setState({
			embargo: date
		});
	}
	
	onInputDateProcessChange(date){
		
		this.setState({
			delay: date
		});
	}
	
	onInputChannelChange(event, type){
		
		let selected = this.state.distribution;
				
		if(event.checked){
			selected[type] = true;
		}else{
			selected[type] = false;
		}							
		
		this.setState({
			distribution: selected
		});
	}
	
	onInputGroupChange(selected){

		this.setState({
			groups: selected
		});
	}
	
	onInputSubscribersChange(selected){

		this.setState({
			sub_list: selected
		});
	}
	
	onInputSocialChange(selected){

		this.setState({
			social_channels: selected
		});
	}

	onSubmit(values) {
		
		if(this.state.readonly){
			return false;
		}
				  				  	
	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  	
	  	posting.type = this.state.type;
	  	posting.description = this.state.description;
	  	posting.date_start = moment(this.state.date.startDate).format('YYYY-MM-DD HH:mm:ss');
	  	posting.date_end = moment(this.state.date.endDate).format('YYYY-MM-DD HH:mm:ss');
	  	delete(posting.date_range);
	  	
	  	if(this.state.delay){
		  	posting.date_process = moment(this.state.delay).format('YYYY-MM-DD HH:mm:ss');
	  	}else{
		  	posting.date_process = 'null';
	  	}
	  	
	  	if(this.state.permissions.embargo){
	  		
	  		if(this.state.embargo){
			  	posting.date_embargo = moment(this.state.embargo).format('YYYY-MM-DD HH:mm:ss');
		  	}else{
			  	posting.date_embargo = 'null';
		  	}
	  	}
	  	
	  	if(this.state.permissions.customise){
	  		posting.customise = this.state.customise ? 1 : 0;
	  	}
	  	
	  	if(this.state.permissions.embargo){
	  		posting.embargo = this.state.embargo ? 1 : 0;
	  	}
	  	
	  	if(this.state.permissions.subscribers){
		  	
		  	let groups = {};
		  			  	
		  	_.forEach(this.state.sub_list, (item, key) => {
						
				let tmp = item;
				
				if(item.value){
					tmp = item.value;
				}
				
				tmp = tmp.split('|');;
				
				if(!groups[tmp[0]]){
					groups[tmp[0]] = [];
				}
				
				groups[tmp[0]].push(tmp[1]);				
			});
		  	
	  		posting.subscribers = groups;
	  	}
	  	
	  	if(this.state.permissions.groups){
		  	
		  	if(this.state.all){
		  		posting.groups = '';
		  	}else{			  	
			  	
			  	posting.groups = _.map(this.state.groups, (group, key) => {					
			  	
				  	if(group.value){
					  	return group.value;
				  	}else{
					  	return group;
				  	}
				  	
				}, this);				
		  	}
	  	}
	  	
	  	if(this.state.permissions.social_channels){
		  	
		  	let groups = {};
		  			  	
		  	_.forEach(this.state.social_channels, (item, key) => {
						
				let tmp = item;
				
				if(item.value){
					tmp = item.value;
				}
				
				tmp = tmp.split('|');;
				
				if(!groups[tmp[0]]){
					groups[tmp[0]] = [];
				}
				
				groups[tmp[0]].push(tmp[1]);				
			});
		  	
	  		posting.social_channels = groups;
	  	}
	  	
	  	posting.distribution_website = this.state.distribution.website ? 1 : 0;
	  	posting.distribution_email = this.state.distribution.email ? 1 : 0;
	  	posting.distribution_landing = this.state.distribution.landing ? 1 : 0;
	  	posting.distribution_social = this.state.distribution.social ? 1 : 0;
	  		  	
	  	this.props.saveCampaignGeneral(formName, this.state.id, posting, (ret) => {
			
			if(ret.status === 'warnings'){	
				
				let ret = `/campaigns/${this.state.id}/`;
				
				if(this.props.proceed){
					ret = `/campaigns/${this.state.id}/assets`;	
				}
				
				this.props.history.push(`/campaigns/${this.state.id}/warnings/${this.state.id}?ret=${Base64.encode(ret)}`);	
								
			}else{
				if(this.props.proceed){
					this.props.history.push(`/campaigns/${ret.campaigns[0].id}/assets`);	
				}
			}
		});
	}
	
	renderDistribution(type){
		
		let options = '';
		let icon = '';
		
		switch(type){
			
			case 'website':
				icon = 'fa-images';
				break;
				
			case 'landing':
				icon = 'fa-browser';
				break;
				
			case 'social':
				icon = 'fa-thumbs-up';
				
				if(this.state.permissions.social_channels){
	
					let groups = [];
					
					if(this.props.retailers.retailers && this.props.retailers.retailers[this.props.account.group.id]){
											
						if(this.props.retailers.retailers[this.props.account.group.id].details.facebook_pages){
							
							_.forEach(this.props.retailers.retailers[this.props.account.group.id].details.facebook_pages, (value, key) => {
																
								groups.push({
									label: value.name,
									value: `facebook|${value.id}`,
									icon: 'fa fa-facebook',
									count: value.fan_count
								});
							});
						}
						
						if(this.props.retailers.retailers[this.props.account.group.id].details.instagram_pages){
							
							_.forEach(this.props.retailers.retailers[this.props.account.group.id].details.instagram_pages, (value, key) => {
																
								groups.push({
									label: value.name,
									value: `instagram|${value.id}`,
									icon: 'fa fa-instagram',
									count: value.followers_count
								});
							});
						}
						
						if(this.props.retailers.retailers[this.props.account.group.id].details.twitter_channels){
							
							_.forEach(this.props.retailers.retailers[this.props.account.group.id].details.twitter_channels, (value, key) => {
								
								groups.push({
									label: value.name,
									value: `twitter|${value.id}`,
									icon: 'fa fa-twitter',
									count: value.followers
								})
							});
						}
					}
					
					let total = 0;
					
					{_.forEach(this.state.social_channels, (group, key) => {
						
						if(group.count){
							total = total+group.count;
						}else{
							let find = _.find(groups, {value:group});
							
							if(find){
								total = total+find.count;
							}
						}
					})}
											
					options = (
						<React.Fragment>
							<Field
								label={i18n.t(`campaigns:settings_distribution_social_channels_label`)}
								labelRight={!this.state.readonly ? i18n.t(`campaigns:settings_distribution_social_channels_total`, { count: total }) : false}
								name="social_channels"
								type={!this.state.readonly ? 'suggest' : 'echo'}
								options={groups}
								selected={this.state.social_channels}
								echo={_.map(_.filter(groups, (v) => _.includes(this.state.social_channels, v.value)), (group, key) => {
									
									return (
										<li key={key}>{group.name}</li>
									);
								})}
								multiple={true}
								onChangeFunc={this.onInputSocialChange.bind(this)}
								component={InputField}
							/>
						</React.Fragment>
					)
				}
				
				
				
				break;
			
			case 'email':
				icon = `fa-envelope`;
								
				if(this.state.permissions.subscribers){
	
					let groups = {};
					
					_.map(this.props.subscribers.groups, (group, key) => {
						return _.assign(groups, _.mapKeys(_.map(group, o => _.extend({source: key}, o)), 'id'));
					});
					
					
					/*
					let groups = _.map(this.props.subscribers.groups, (group, key) => {
												
						if(Object.keys(this.props.subscribers.groups).length > 1){
								
							let label = key;
							
							switch(key){
								
								case 'mailchimp':
									label = 'MailChimp';
									break;
									
								case 'local':
									label = 'Local';
									break;
									
								default:
								
									break;
							}
							
							return {
								label: label,
								options: _.map(group, (value, key2) => {
									
									return {
										label: value.name,
										value: value.id
									}
								})
						    }
						}else{
							return _.map(group, (value, key2) => {
									
								return {
									label: value.name,
									value: value.id
								}
							})
						}
					});*/
					
					
					let subGroups = _.map(groups, (group, key) => {
								
						let icon = 'fal fa-at';	
						let count = 0;
						let prefix = 'local';

						if(group.source){
							
							switch(group.source){
								
								case 'mailchimp':
									prefix = group.source;
									icon = 'fab fa-mailchimp';
									break;
									
								default: 
								
									break;
							}
						}
						
						if(group.subscribers){
																		
							if(Array.isArray(group.subscribers)){
								count = group.subscribers.length;
							}else{
								count = group.subscribers;
							}
						}
																							
						return ({
							name: group.name,
							label: `${group.name} (${count})`,
							value: `${prefix}|${group.id}`,
							icon: icon,
							count: count
						});
					});
					
					let total = 0;
					
					{_.forEach(this.state.sub_list, (group, key) => {
						
						if(group.count){
							total = total+group.count;
						}else{
							let find = _.find(subGroups, {value:group});
							
							if(find){
								total = total+find.count;
							}
						}
					})}
						
					options = (
						<React.Fragment>
							<Field
								label={i18n.t(`campaigns:settings_distribution_email_lists_label`)}
								labelRight={!this.state.readonly ? i18n.t(`campaigns:settings_distribution_email_lists_total`, { count: total }) : false}
								name="subscribers"
								type={!this.state.readonly ? 'suggest' : 'echo'}
								options={subGroups}
								selected={this.state.sub_list}
								echo={_.map(_.filter(subGroups, (v) => _.includes(this.state.sub_list, v.value)), (group, key) => {
									
									return (
										<li key={key}>{group.name}</li>
									);
								})}
								multiple={true}
								onChangeFunc={this.onInputSubscribersChange.bind(this)}
								component={InputField}
							/>
						</React.Fragment>
					)
				}
				break;
			
			default:
			
				break;
		}
		
		return (
			<div className={`col-xl-6 ${this.state.distribution[type] ? '' : 'disabled'}`}>
				
				<div className="c-card equalize">
					
					<h5 className="u-mb-small">
						<i className={`far ${icon}`}></i>
						{i18n.t(`campaigns:settings_distribution_${type}_title`)}
						<div className="options" style={{ marginRight: "-10px" }}>
							<Field
								name={type}
								type="switch"
								selected={this.state.distribution[type]}
								disabled={this.state.readonly ? true : false}
								onChangeFunc={(event) => { this.onInputChannelChange(event.target, type); } }
								component={InputField}
							/>
						</div>
					</h5>
					<p className={`u-mb-small ${this.state.distribution.website ? '' : 'disabled'}`}>
						{i18n.t(`campaigns:settings_distribution_${type}_description`)}
					</p>
					
					{this.state.distribution[type] && 
						options
					}
				</div>
			</div>
		)
	}
	
	descriptionAddRemove(type, key){
		
		let description = this.state.description;
		let description_counter = this.state.description_counter;
				
		if(type === 'add'){
			++description_counter;
			description[description_counter] = '';
		}else{
			
			delete(description[key])
			
			//description.splice(key, 1);
			//this.props.change(`description_${key}`, '');
			
			if(Object.keys(description).length === 0){
				++description_counter;
				description[description_counter] = '';
			}
		}
										
		this.setState({
			description: description,
			description_counter: description_counter
		});	
		
		_.forEach(description, (item, key) => {					
			this.props.change(`description_${key}`, item);
		});
	}
	
	onInputChangeDescription(key, value){

		let description = this.state.description;

		description[key] = value.target.value;
		
		this.setState({
			description: description
		});
	}
	
	render() {

		let { campaigns } = this.props;
		
		if(this.state.id && (!campaigns.campaigns || !campaigns.campaigns[this.state.id])){
			return (
				<Loading />
			);
		}
				
		const { handleSubmit } = this.props;
		
		let customise = true;
		let campaign = false;
		
		if(this.state.id){
			
			campaign = campaigns.campaigns[this.state.id];
						
			if(!this.state.readonly && !this.state.permissions.customise && campaign.customise === 0){
				customise = false;
			}
		}
		
		let types = [
			/*{
				label: 'Event',
				value: 'event'
			},*/
			{
				label: 'Generic',
				value: 'generic'
			},
			{
				label: 'Promotion/Sale',
				value: 'promo'
			},
			{
				label: 'Tier 1 Product Launch',
				value: 'tier1'
			},
			{
				label: 'Tier 2 Moderate',
				value: 'tier2'
			},
			{
				label: 'Tier 3 Light',
				value: 'tier3'
			}
		];
		
		let type = _.filter(types, { value: campaign.type });
		let i = 0;
		
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
			
				<CampaignsToolbar
					formName={formName}
					id={this.state.id}
					readonly={this.state.readonly}
					start={this.state.date.startDate}
					section="setup"
					history={this.props.history}
					deletable={this.state.deletable}
					campaign={campaign}
				/>
				
				<div className="container">				
					<div className="row">
						<div className={`col-xl-${this.state.permissions.groups ? 8 : 12} col-md-12`}>
						
							<div className="c-card equalize">
															
								<h4 className={customise ? 'u-mb-medium' : null}>{i18n.t('campaigns:settings_title_general')} </h4>
								
								{!customise && 
									<p className="u-mb-medium">
										{i18n.t('campaigns:settings_content_locked')}
									</p>
								}
								
								<div className="row">
									<div className="col-xl-6 col-md-12">
										<Field
											label={i18n.t('campaigns:settings_label_type')} 
											name="type"
											type={(!this.state.readonly && customise) ? 'suggest' : 'echo'}
											options={types}
											selected={this.state.type}
											echo={type[0] ? type[0].label : ''}
											onChangeFunc={this.onInputTypeChange.bind(this)}
											component={InputField}
										/>
																				
										<Field
											label={i18n.t('campaigns:settings_label_internal_name')} 
											name="internal_name"
											type={!this.state.readonly ? 'text' : 'echo'}
											echo={campaign.internal_name}
											component={InputField}
										/>
										
										<Field
											label={i18n.t('campaigns:settings_label_date_start')} 
											name="date_start"
											type="datepicker"
											timepicker={true}
											disabled={this.state.readonly ? true : false}
											selected={this.state.date.startDate}
											onChangeFunc={(date) => { this.onInputDateChange('startDate', date); }}
											component={InputField}
											minDate={this.state.embargo ? this.state.embargo : moment().toDate()}
										/>
										
										<Field
											label={i18n.t('campaigns:settings_label_date_end')} 
											name="date_end"
											type="datepicker"
											timepicker={true}
											disabled={this.state.readonly ? true : false}
											selected={this.state.date.endDate}
											onChangeFunc={(date) => { this.onInputDateChange('endDate', date); }}
											component={InputField}
											minDate={this.state.date.startDate ? this.state.date.startDate : moment().toDate()}
										/>
										
										<Field
											label={i18n.t('campaigns:settings_label_public_name')} 
											name="public_name"
											type={(!this.state.readonly && customise) ? 'text' : 'echo'}
											echo={campaign.public_name}
											component={InputField}
										/>
										<Field
											label={i18n.t('campaigns:settings_label_public_subline')} 
											name="public_subline"
											type={(!this.state.readonly && customise) ? 'text' : 'echo'}
											echo={campaign.public_subline}
											component={InputField}
										/>
									</div>
									<div className="col-xl-6 col-md-12">
										{_.map(this.state.description, (item, key) => {
									
											++i;
											let labelIcon = '';
				
											if(!this.state.readonly && customise){
												labelIcon = (
													<React.Fragment>
														<i className="fal fa-trash" onClick={() => { this.descriptionAddRemove('remove', key); }}></i>
													</React.Fragment>				
												);
											}	
											
											return (
												<Field
													key={key}
													label={i18n.t('campaigns:settings_label_promotion_text', { count: i })} 
													labelIcon={labelIcon}
													name={`description_${key}`}
													type={(!this.state.readonly && customise) ? 'textarea-auto' : 'echo'}
													value={item.value}
													echo={item}
													component={InputField}
													onChangeFunc={this.onInputChangeDescription.bind(this, key)}
												/>
											);
										})}
										
										{!this.state.readonly && customise && 
											<button
												className="c-btn c-btn--outline" 
												type="button"
												onClick={() => { this.descriptionAddRemove('add'); }}
											>
												{i18n.t('campaigns:settings_action_promotion_text_add')}
											</button>
										}
									</div>
								</div>
							</div>
						</div>						
						
						{this.state.permissions.groups && 
							<div className="col-xl-4 col-md-12">
								<div className="c-card equalize">
									<h4 className="u-mb-medium">{i18n.t('campaigns:settings_title_retailers')}</h4>
									
									<Field
										name="groups_all"
										type="switch"
										labelSwitch={i18n.t('campaigns:settings_label_groups_all')} 
										selected={this.state.all}
										disabled={this.state.readonly ? true : false}
										onChangeFunc={(event) => {
											this.setState({
												all: event.target.checked
											});							
										}}
										component={InputField}
									/>
										
									{!this.state.all && 
									
										<Field
											label={i18n.t('campaigns:settings_label_groups_select')} 
											name="groups"
											type={!this.state.readonly ? 'suggest' : 'echo'}
											options={_.map(this.props.retailers.groups, (group, key) => {
												
												return {
													label: group.name,
													value: group.id
												}
											})}
											selected={this.state.groups}
											echo={_.map(_.filter(this.props.retailers.groups, (v) => _.includes(this.state.groups, v.id)), (group, key) => {
												
												return (
													<li key={key}>{group.name}</li>
												);
											})}
											onChangeFunc={this.onInputGroupChange.bind(this)}
											multiple={true}
											component={InputField}
										/>
									}
									
									<hr className="splitter u-mb-small" />
									
									{this.state.permissions.customise && 
												
										<React.Fragment>
											<Field
												name="customise"
												type="switch"
												labelSwitch={i18n.t('campaigns:settings_label_customise')} 
												selected={this.state.customise}
												disabled={this.state.readonly ? true : false}
												onChangeFunc={(event) => {
													this.setState({
														customise: event.target.checked
													});							
												}}
												component={InputField}
											/>
											
											<hr className="splitter u-mb-small" />
										</React.Fragment>
									}
									
									{this.state.permissions.embargo && 
											
										<React.Fragment>
											<Field
												name="embargo"
												type="switch"
												labelSwitch={i18n.t('campaigns:settings_label_embargo')} 
												selected={this.state.embargo}
												disabled={this.state.readonly ? true : false}
												onChangeFunc={(event) => {
											
													let embargo = false;
													
													if(event.target.checked){
														embargo = moment().toDate(); //this.state.date.startDate
													}
													
													this.setState({
														embargo: embargo
													});							
												}}
												component={InputField}
											/>
											
											{this.state.embargo && 
									
												<Field
													label={i18n.t('campaigns:settings_label_embargo_date')} 
													name="date_embargo"
													type="datepicker"
													timepicker={true}
													disabled={this.state.readonly ? true : false}
													selected={this.state.embargo}
													onChangeFunc={this.onInputDateEmbargoChange.bind(this)}
													component={InputField}
													minDate={moment().toDate()}
													maxDate={this.state.date.startDate}
												/>	
											}
																						
											<hr className="splitter u-mb-small" />
										</React.Fragment>
									}									
									
									<Field
										name="delay"
										type="switch"
										labelSwitch={i18n.t('campaigns:settings_label_delay')} 
										selected={this.state.delay}
										disabled={this.state.readonly ? true : false}
										onChangeFunc={(event) => {
											
											let delay = false;
											
											if(event.target.checked){
												delay = moment().toDate(); //this.state.date.startDate
											}
											
											this.setState({
												delay: delay
											});							
										}}
										component={InputField}
									/>
										
									{this.state.delay && 
									
										<Field
											label={i18n.t('campaigns:settings_label_delay_date')} 
											name="date_process"
											type="datepicker"
											timepicker={true}
											disabled={this.state.readonly ? true : false}
											selected={this.state.delay}
											onChangeFunc={this.onInputDateProcessChange.bind(this)}
											component={InputField}
											minDate={moment().toDate()}
											maxDate={this.state.date.startDate}
										/>	
									}
										
								</div>
							</div>
						}
											
					</div>
	
					
					<div className="c-card">
						<h4 className="u-mb-medium">{i18n.t('campaigns:settings_title_distribution_methods')}</h4>
				
						<div className="row">
							{this.renderDistribution('website')}
							{this.renderDistribution('email')}
							{this.renderDistribution('landing')}
							{this.renderDistribution('social')}
						</div>
					</div>
				</div>
			</form>				
		);
	}
}

const validate = (values, props) => {	
		
	const errors = {};
	
	let date_start = false;
		
	if(!values.date_start){
		errors.date_start = 'Choose a start date!';
	}else if(values.date_start){
		
		date_start = moment(values.date_start).tz(props.account.profile.timezone).toDate();
		
		if(date_start.date){
			date_start = moment.utc(date_start.date).toDate();
		}	
						
		if(date_start < moment.tz('UTC').toDate()){
			errors.date_start = 'Start date must be in the future!';
		}
	}	
		
	if(!values.date_end){
		errors.date_end = 'Choose an end date!';
	}else if(values.date_end){
		
		let date = moment(values.date_end).tz(props.account.profile.timezone).toDate();
		
		if(date.date){
			date = moment.utc(date.date).toDate();
		}	
						
		if(date < moment.tz('UTC').toDate()){
			errors.date_end = 'End date must be in the future!';
		}else if(date_start && date <= date_start){
			errors.date_end = 'End date must be after start date!';
		}
	}
		
	if (!values.type) {
		errors.type = 'Choose campaign type!';
	}
	
	if (!values.internal_name) {
		errors.internal_name = 'Enter an internal name!';
	}
	
	if (!values.public_name) {
		errors.public_name = 'Enter a public name!';
	}
	
	if (!values.groups || values.groups.length === 0) {
		errors.groups = 'Choose at least 1 retailer group!';
	}
		
	if (!values.subscribers || values.subscribers.length === 0) {
		errors.subscribers = 'Choose at least 1 subscriber group!';
	}
	
	if (!values.social_channels || values.social_channels.length === 0) {
		errors.social_channels = 'Choose at least 1 social channel!';
	}
		
	let i = 0;
	
	while (i < 100) {
		if (!values[`description_${i}`]) {
			errors[`description_${i}`] = 'Please enter a description!';
		}
		i++;
	}
	
	return errors;
}

function mapStateToProps({ campaigns, forms, account, retailers, subscribers }, ownProps){
	return {
		campaigns,
		proceed: forms.proceed,
		account, 
		retailers,
		subscribers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		saveCampaignGeneral,
		setSectionOptions,
		fetchCampaignsList,
		fetchRetailersGroups,
		fetchSubscribersGroups,
		fetchRetailersList
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(CampaignsSetup)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import Swal from 'sweetalert2';
import { checkPermission } from '../../helpers/permissions';
import { regenerateCampaignEvents, deleteCampaign } from '../../actions/campaigns';
import moment from 'moment';
import { showNotification } from '../../actions/notifications';

class CampaignsToolbar extends Component {

	render() {
		
		let tabs = [];
		let buttons = [];
		let filter = false;

		if(this.props.id){
			
			tabs = [
				{
					label: i18n.t('campaigns:toolbar_setup'),
					url: `/campaigns/${this.props.id}`,
					selected: this.props.section === 'setup' ? true : false
				},
				{
					label: i18n.t('campaigns:toolbar_assets'),
					url: `/campaigns/${this.props.id}/assets`,
					selected: this.props.section === 'assets' ? true : false,
					counter: this.props.campaign.items.articles.length + this.props.campaign.items.assets.length + this.props.campaign.items.products.length
					
				},
				{
					label: i18n.t('campaigns:toolbar_schedule'),
					url: `/campaigns/${this.props.id}/schedule`,
					selected: this.props.section === 'schedule' ? true : false,
					counter: this.props.campaign.events.length
				}
			];
			
			if(this.props.readonly && checkPermission(this.props.account.permissions, 'CAMPAIGNS_ANALYTICS')){
				tabs.push({
					label: i18n.t('campaigns:toolbar_analytics'),
					url: `/campaigns/${this.props.id}/analytics`,
					selected: this.props.section === 'analytics' ? true : false
				});
			}
			
		}else{
			
			tabs = [{
				label: i18n.t('campaigns:toolbar_setup'),
				url: `/campaigns/add`,
				selected: this.props.section === 'setup' ? true : false
			}];
		}

		switch(this.props.section){
				
			case 'setup':
				if(this.props.id && this.props.deletable){
					
					if(this.props.readonly && moment(this.props.start) < moment()){
					
						buttons.push({
							onClick: () => {
								
								Swal.fire({
									title: i18n.t('campaigns:deactivate_dialog_title'),
									text: i18n.t('campaigns:deactivate_dialog_description'),
									icon: 'warning',
									showCancelButton: true,
									confirmButtonText: i18n.t('dialog_confirm'),
									cancelButtonText: i18n.t('dialog_cancel'),
									focusConfirm: false
								}).then((result) => {
	
									if(result.value) {
										this.props.deleteCampaign(this.props.id, () => {
											
											this.props.showNotification(
												'success',
												i18n.t('campaigns:notification_setup_deactivated_title'),
												i18n.t('campaigns:notification_setup_deactivated_description')
											);
											
											this.props.history.push('/campaigns');
										});
									}
								});
							},
							outline: true,
							className: "c-btn--danger",
							label: i18n.t('campaigns:settings_deactivate_submit'),
						});
						
					}else{
						
						buttons.push({
							onClick: () => {
								
								Swal.fire({
									title: i18n.t('campaigns:delete_dialog_title'),
									text: i18n.t('campaigns:delete_dialog_description'),
									icon: 'warning',
									showCancelButton: true,
									confirmButtonText: i18n.t('dialog_confirm'),
									cancelButtonText: i18n.t('dialog_cancel'),
									focusConfirm: false
								}).then((result) => {
	
									if(result.value) {
																				
										this.props.deleteCampaign(this.props.id, () => {
											
											this.props.showNotification(
												'success',
												i18n.t('campaigns:notification_setup_deleted_title'),
												i18n.t('campaigns:notification_setup_deleted_description')
											);
											
											this.props.history.push('/campaigns');
										});
									}
								});
							},
							outline: true,
							className: "c-btn--danger",
							label: i18n.t('campaigns:settings_delete_submit'),
						});
					}
					
					/*buttons.push({
						label: i18n.t('campaigns:settings_save_submit'),
						submit: this.props.formName,
						outline: true
					});*/
				}
				
				if(!this.props.readonly){
					buttons.push({
						label: i18n.t('campaigns:settings_save_submit'),
						submit: this.props.formName,
						proceed: true
					});
				}
				break;
				
			case 'assets':
				if(!this.props.readonly){
					buttons.push({
						label: i18n.t('campaigns:assets_action_select'),
						onClick: this.props.onClick.select,
						outline: true
					});

					if(this.props.continue){
						buttons.push({
							label: i18n.t('campaigns:assets_action_continue'),
							url: `/campaigns/${this.props.id}/schedule`
						});
					}
				}
				break;
				
			case 'schedule':
				if(!this.props.readonly){
										
					buttons.push({
						icon: 'fa-redo',
						onClick: () => {
							
							Swal.fire({
								title: i18n.t('campaigns:schedule_regenerate_dialog_title'),
								text: i18n.t('campaigns:schedule_regenerate_dialog_description'),
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: i18n.t('dialog_confirm'),
								cancelButtonText: i18n.t('dialog_cancel'),
								focusConfirm: false
							}).then((result) => {

								if (result.value) {
									this.props.regenerateCampaignEvents(this.props.id); 
								}
							});
						},
						outline: true,
						className: "c-btn--danger"
					},
					
					 // TODO: Enable this when add events possible
					/*{
						label: i18n.t('campaigns:schedule_action_add'),
						onClick: this.props.onClick.add,
						outline: true
					}*/);
					
					if(this.props.continue){
						
						if(checkPermission(this.props.account.permissions, 'CAMPAIGNS_DEPLOY')){
							
							buttons.push({
								label: i18n.t('campaigns:schedule_action_deploy'),
								onClick: this.props.onClick.deploy
							});
							
						}else{
							buttons.push({
								label: i18n.t('campaigns:schedule_action_launch'),
								onClick: this.props.onClick.launch
							});
						}
					}
				}
				
				/*filter={
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('keyword'),
							name: "keyword",
							type: 'text',
							value: this.state.keyword ? this.state.keyword : ''
						}
					],
					open: this.state.search
				}*/
				break;	
				
			case 'analytics':
				/*buttons.push({
					label: i18n.t('campaigns:analytics_action_export'),
					onClick: () => { alert('coming soon') }
				});*/
				break;				
				
			default:
			
				break;			
		}

		return (
			<Toolbar
				tabs={tabs}
				tabsSteps={true}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}



function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	regenerateCampaignEvents,
	deleteCampaign,
	showNotification
})(CampaignsToolbar);
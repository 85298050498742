import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import { fetchAssetsList } from '../../actions/assets';
import Loading from '../../components/chrome/loading';
import { makeSelection } from '../../actions/library';
import AssetToolbar from './toolbar';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player'
import { imageUrl, imageResize } from '../../helpers/s3';

class AssetsView extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push(this.props.parentPath);
		}		
				
		this.state = {
			id: id
		}
		
		this.props.fetchAssetsList(id, () => {
		       	
	       	if(this.props.assets.assets && !this.props.assets.assets[id]){
				this.props.history.push(this.props.parentPath);
			}
       	});
	}
		
	renderPreview(){
		
		let asset  = this.props.assets.assets[this.state.id];
		let url = '';
		
		if(asset.type === 'vimeo' || asset.type === 'youtube' || asset.type === 'video'){
			
			switch(asset.type){
				
				case 'vimeo':
					url = `https://vimeo.com/${asset.filename}`;
					break;
					
				case 'youtube':
					url = `https://youtube.com/watch?v=${asset.filename}`;
					break;
					
				default:
					url = imageUrl(asset.filename);
					break;
			}
			
			/*let playIcon = (
				<i className="fad fa-play-circle play-video"></i>
			);*/
			
			let playIcon = (
				<img src={imageUrl('general/play.png')} width="75" alt={asset.title} />
			);


			return (
				<ReactPlayer
			        url={url}
			        playing={true}
			        controls={true}
			        light={imageUrl(asset.thumbnail)}
			        config={{
						youtube: {
							playerVars: { 
								modestbranding: 1
							}
						},
						vimeo: {
							title: false
						}
					}}
					playIcon={playIcon}
			     />
			);
			
		}else if(asset.type === 'image'){
			
			return (
				<img src={imageResize(asset.filename, 1000)} alt={asset.name} />
			);
			
		}else{
					
			return (
				<FileViewer
			        fileType={asset.extension}
			        filePath={imageUrl(asset.filename)}
			     />
			);
		} 
	}
	
	render() {
		
		if(!this.props.assets.assets || !this.props.assets.assets[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let asset  = this.props.assets.assets[this.state.id];
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{asset.name}
					</h2>
				</header>
				
				<AssetToolbar
					id={this.state.id}
					section="view"
					readonly={asset.readonly === 1 ? true : false}
					{...this.props}
				/>
				
				<div className="view-section">
					{this.renderPreview()}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, overlay }, ownProps){
	return {
		assets,
		overlay
	};
}

export default connect(mapStateToProps, { 
	fetchAssetsList,
	makeSelection
})(AssetsView);
import { campaignsConstants } from '../constants/campaigns';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchCampaignsList(id, callback) {

    return async (dispatch) => {
		
		let url = '/campaign/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: campaignsConstants.CAMPAIGN_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			console.log(error)
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchCampaignsImportList(id, callback) {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get('/campaign/import');
										
			dispatch({ 
				type: campaignsConstants.CAMPAIGN_IMPORT_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveCampaignGeneral(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
				
		try {
						
			if(id){
				request = await axiosCreate().patch(`/campaign/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/campaign/save', values);
			}
			
			if(request.data.status === 'success'){
				
				dispatch({
					type: campaignsConstants.CAMPAIGN_SAVE_GENERAL,
					payload: request
				});
				
				if(id){
					dispatch(showNotification(
						'success',
						i18n.t('campaigns:notification_setup_updated_title'),
						i18n.t('campaigns:notification_setup_updated_description')
					));
				}else{
					dispatch(showNotification(
						'success',
						i18n.t('campaigns:notification_setup_added_title'),
						i18n.t('campaigns:notification_setup_added_description')
					));
				}
			}else{
				dispatch({
					type: campaignsConstants.CAMPAIGN_SAVE_WARNINGS,
					posted: values,
					id: id, 
					payload: request
				});
			}
			
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			
			console.log(error);
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function deleteCampaign(id, callback) {

	return async (dispatch) => {

		const request = await axiosCreate().delete(`/campaign/delete/${id}`);
		
		try {

			dispatch({
				type: campaignsConstants.CAMPAIGN_DELETE,
				payload: request
			});
						
			if(callback)
				callback();
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveCampaignItems(formName, id, values, callback) {

	return async (dispatch) => {
		
		try {
						
			const request = await axiosCreate().put('/campaign/items', { id: id, items: values });

			dispatch({
				type: campaignsConstants.CAMPAIGN_SAVE_ASSETS,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('campaigns:notification_assets_updated_title'),
				i18n.t('campaigns:notification_assets_updated_description')
			));
			
			if(callback)
				callback(id);
			
			dispatch(stopSubmission(formName));

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function toggleCampaignItemHeadline(campaign_id, reference_id, status, callback) {

	return async (dispatch) => {
		
		try {
									
			const request = await axiosCreate().patch(`/campaign/item/${campaign_id}`, { id: reference_id, type: 'headline', value: status });
					
			dispatch({
				type: campaignsConstants.CAMPAIGN_SAVE_ASSETS,
				payload: request
			});
			
			if(status === 1){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_enabled_title'),
					i18n.t('assets:notification_headline_enabled_description'),
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_disabled_title'),
					i18n.t('assets:notification_headline_disabled_description'),
				));
			}	
			
			if(callback)
				callback();

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function restrictCampaignItemHeadlinePromo(campaign_id, reference_id, promo, callback) {

	return async (dispatch) => {
		
		try {
									
			const request = await axiosCreate().patch(`/campaign/item/${campaign_id}`, { id: reference_id, type: 'promo', value: promo });
					
			dispatch({
				type: campaignsConstants.CAMPAIGN_SAVE_ASSETS,
				payload: request
			});
						
			if(promo.length > 0){
				dispatch(showNotification(
					'success',
					i18n.t('campaigns:notification_headline_promo_restricted_title'),
					i18n.t('campaigns:notification_headline_promo_restricted_description'),
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('campaigns:notification_headline_promo_unrestricted_title'),
					i18n.t('campaigns:notification_headline_promo_unrestricted_description'),
				));
			}	
			
			if(callback)
				callback();

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveCampaignEvent(formName, id, values, callback) {

	return async (dispatch) => {

		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/schedule/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/schedule/save', values);
			}
			
			dispatch({
				type: campaignsConstants.CAMPAIGN_SAVE_EVENT,
				campaign_id: request.data.event.campaign_id,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('campaigns:notification_event_updated_title'),
					i18n.t('campaigns:notification_event_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('campaigns:notification_event_added_title'),
					i18n.t('campaigns:notification_event_added_description')
				));
			}
						
			if(callback)
				callback(request.data.event.id);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function deleteCampaignEvent(id, callback) {

	return async (dispatch) => {

		const request = await axiosCreate().delete(`/schedule/delete/${id}`);
		
		try {

			dispatch({
				type: campaignsConstants.CAMPAIGN_DELETE_EVENT,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('campaigns:notification_event_deleted_title'),
				i18n.t('campaigns:notification_event_deleted_description')
			));
						
			if(callback)
				callback();
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
					};
	}
}


export function regenerateCampaignEvents(id, callback) {

	return async (dispatch) => {
		
		try {
						
			const request = await axiosCreate().post(`/schedule/regenerate/${id}`);
			
			dispatch({
				type: campaignsConstants.CAMPAIGN_REGENERATE_EVENTS,
				campaign_id: id,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('campaigns:notification_schedule_regenerated_title'),
				i18n.t('campaigns:notification_schedule_regenerated_description')
			));
						
			if(callback)
				callback(request.data.event.id);
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function deployCampaign(id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post('/campaign/deploy', { id: id });

			dispatch({
				type: campaignsConstants.CAMPAIGN_DEPLOY,
				payload: request
			});
			
			if(callback)
				callback();
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function importCampaign(id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post('/campaign/import', { id: id });

			dispatch({
				type: campaignsConstants.CAMPAIGN_IMPORT,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('campaigns:notification_imported_title'),
				i18n.t('campaigns:notification_imported_description')
			));
			
			callback(request.data.campaigns[0].id);

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function launchCampaign(id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post('/campaign/launch', { id: id });

			dispatch({
				type: campaignsConstants.CAMPAIGN_LAUNCH,
				payload: request
			});
			
			if(callback)
				callback();
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}
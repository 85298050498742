import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { imageResize } from '../../helpers/s3';
import { fetchDeveloperRetailer } from '../../actions/retailers';
import Loading from '../../components/chrome/loading';

import i18n from '../../helpers/i18n'; 

import IntegrationsCode from '../../components/retailers/integrations/code';
import IntegrationsDomain from '../../components/retailers/integrations/domain';
import IntegrationsSlider from '../../components/retailers/integrations/slider';
import IntegrationsAdvert from '../../components/retailers/integrations/advert';
import IntegrationsConversion from '../../components/retailers/integrations/conversion';
import IntegrationsEmail from '../../components/retailers/integrations/email';

class Developer extends Component {

	constructor(props) {
	    super(props);
	    
	    let { token, type } = this.props.match.params;
	    
	    if(!token){
		    token = false;
	    }
	    
	    if(token && type){
		    
		    switch(type){
			    case 'code':
			    case 'domain':
			    case 'slider':
			    case 'advert':
			    case 'conversion':
			    case 'email':
			    	// nothing to do
			    	break;
			    	
			    default:
			    	this.props.history.push(`/developer/${token}`);
			    	break;
		    }		    
	    }
	    
	    this.state = {
		    token: token,
		    type: type
	    }
	    
	    if(token){
		    
		    this.props.fetchDeveloperRetailer(token, () => {
		       	
		       	if(!this.props.retailers.developer){
					
					this.setState({
					    token: false
				    });
				    
				    this.props.history.push('/developer');
				}
	       	});
	    }
	}
	
	componentDidUpdate(prevProps, prevState) {
		
		let { type } = this.props.match.params;
		
		if(!type){
			type = false;
		}
		
		if(type !== this.state.type){
			this.setState({
		        type: type
	        });
		}
	}
	
	render(){
		
		let retailer = false;
		
		if(this.state.token){
			
			if(!this.props.retailers.developer){
				return (
					<Loading />
				);
			}
			
			retailer = this.props.retailers.developer;
		}	
		
		let typeContent = 'Unable to find settings';
		
		if(this.state.type){
						
			switch(this.state.type){
				
			    case 'code':
			    	typeContent = (
				    	<IntegrationsCode 
							retailer={retailer}
						/>
			    	);
			    	break;
			    	
			    case 'domain':
			    	typeContent = (
				    	<IntegrationsDomain
							retailer={retailer}
						/>
			    	);
			    	break;
			   
			    case 'slider':
			    	typeContent = (
				    	<IntegrationsSlider
							retailer={retailer}
						/>
			    	);
			    	break;
			   
			    case 'advert':
			    	typeContent = (
				    	<IntegrationsAdvert
							retailer={retailer}
						/>
			    	);
			    	break;
			   
			    case 'conversion':
			    	typeContent = (
				    	<IntegrationsConversion
							retailer={retailer}
							code_url={`/developer/${this.state.token}/code`}
						/>
					);
			    	break;
			   
			    case 'email':
			    	typeContent = (
				    	<IntegrationsEmail
							retailer={retailer}
						/>
					);
			    	break;
			    	
			    default:
			    	break;
		    }
			
		}
				
		return (
			<div className="o-page o-page--center">
        		{this.props.brand.details &&
					<img className="u-mb-small logo" src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
				}
				<div className="o-page__card" style={{ width: '100%', maxWidth: 700 }}>
					<div className="c-card c-card--center">										
						
						{this.state.type && 
							
							<React.Fragment>
								<div className="top">									
									<h3>
										<Link to={`/developer/${this.state.token}`}>
											{i18n.t('developers:title')}
										</Link> | {i18n.t(`developers:${this.state.type}_title`)}
									</h3>
									<p>{i18n.t(`developers:${this.state.type}_description`)}</p>
																					
								</div>
								
								<div className="c-card c-alertc-alert--danger u-mb-none u-mt-medium" style={{ textAlign: 'left' }}>
					                {typeContent}
								</div>
							
							</React.Fragment>
						
						}
						
						{!this.state.type && 
							
							<React.Fragment>
							
								{!this.state.token && 
									<React.Fragment>
										<div className="top">
											<h3>{i18n.t('developers:title')}</h3>
											<p>{i18n.t('developers:invalid_title', { brand: this.props.brand.details.title })}</p>
										</div>
										
										<div className="c-card c-alertc-alert--danger u-mb-none u-mt-medium">
							                <span className="c-alert__icon u-mb-small c-alert--danger" style={{ position: 'initial' }}>
												<i className="fal fa-store-alt" style={{ color: '#FFF' }}></i>
											</span>
											<br/>
										
											<div>
												<h4 className="c-alert__title">{i18n.t('developers:invalid_warning_title')}</h4>
												<p className="u-mb-small">{i18n.t('developers:invalid_warning_description')}</p>
											</div>
										</div>
										
										
									</React.Fragment>
								}
								
								{this.state.token && retailer && 
									<React.Fragment>
										<div className="top">
											<h3>{i18n.t('developers:title')}</h3>
											<p>{i18n.t('developers:intro', { brand: this.props.brand.details.title, retailer: retailer.name })}</p>
										</div>
										
										<div className="row u-pb-zerosite"> 
										
											<Link to={`/developer/${this.state.token}/code`} className="col-xl-6 col-sm-12">
												<div className="c-card equalize">
													<h5 className="u-mb-small">
														{i18n.t('developers:code_title')}
													</h5>
													<p>{i18n.t('developers:code_description')}</p>
													
													<button className="u-mt-medium c-btn c-btn--info">
														{i18n.t(`developers:code_cta`)}
													</button>
												</div>
											</Link>
											
											{retailer.domains && retailer.domains.custom && 
												<Link to={`/developer/${this.state.token}/domain`} className="col-xl-6 col-sm-12">
													<div className="c-card equalize">
														<h5 className="u-mb-small">
															{i18n.t('developers:domain_title')}
														</h5>
														<p>{i18n.t('developers:domain_description', { domain: retailer.domains.custom.domain })}</p>
														
														<button className="u-mt-medium c-btn c-btn--info">
															{i18n.t(`developers:domain_cta`)}
														</button>
													</div>
												</Link>
											}
											
											<Link to={`/developer/${this.state.token}/slider`} className="col-xl-6 col-sm-12">
												<div className="c-card equalize">
													<h5 className="u-mb-small">
														{i18n.t('developers:slider_title')}
													</h5>
													<p>{i18n.t('developers:slider_description')}</p>
													
													<button className="u-mt-medium c-btn c-btn--info">
														{i18n.t('developers:slider_cta')}
													</button>
												</div>
											</Link>
											<Link to={`/developer/${this.state.token}/advert`} className="col-xl-6 col-sm-12">
												<div className="c-card equalize">
													<h5 className="u-mb-small">
														{i18n.t('developers:advert_title')}
													</h5>
													<p>{i18n.t('developers:advert_description')}</p>
													
													<button className="u-mt-medium c-btn c-btn--info">
														{i18n.t('developers:advert_cta')}
													</button>
												</div>
											</Link>
											<Link to={`/developer/${this.state.token}/conversion`} className="col-xl-6 col-sm-12">
												<div className="c-card equalize">
													<h5 className="u-mb-small">
														{i18n.t('developers:conversion_title')}
													</h5>
													<p>{i18n.t('developers:conversion_description')}</p>
													
													<button className="u-mt-medium c-btn c-btn--info">
														{i18n.t('developers:conversion_cta')}
													</button>
												</div>
											</Link>
											
											<Link to={`/developer/${this.state.token}/email`} className="col-xl-6 col-sm-12">
												<div className="c-card equalize">
													<h5 className="u-mb-small">
														{i18n.t('developers:email_title')}
													</h5>
													<p>{i18n.t('developers:email_description')}</p>
													
													<button className="u-mt-medium c-btn c-btn--info">
														{i18n.t('developers:email_cta')}
													</button>
												</div>
											</Link>
										</div>
										
									</React.Fragment>
								}
							</React.Fragment>
						}
					</div>
				</div>
				
				<div className="poweredby">
					Powered by <a href="https://www.objective1.co.uk" target="_blank" rel="noopener noreferrer">{this.props.brand.details.title}</a>
				</div>
        	</div>
		);
	}
}

function mapStateToProps({ retailers, brand }, ownProps){
	return {
		retailers,
		brand
	};
}

export default connect(mapStateToProps, { 
	fetchDeveloperRetailer
})(Developer);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import Loading from '../../components/chrome/loading';
import { checkPermission } from '../../helpers/permissions';
import { fetchCampaignsList, deployCampaign } from '../../actions/campaigns';
import { fetchRetailersGroups, fetchRetailersList } from '../../actions/retailers';
import { usersFetch } from '../../actions/users';
import Submit from '../../components/forms/submit';
import { showNotification } from '../../actions/notifications';
import CountUp from 'react-countup';
import { imageResize } from '../../helpers/s3';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Table from '../../components/chrome/table';
import _ from 'lodash';

class CampaignsDeploy extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_DEPLOY')){
			this.props.history.push('/');
		}
		
		moment.locale(i18n.language);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		
				
		this.state = {
			id: id,
			groups: false,
			errors: []
		}
		
		this.props.fetchCampaignsList(id, () => {
		    
	       	if(this.props.campaigns.campaigns && !this.props.campaigns.campaigns[this.state.id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});

       	this.props.fetchRetailersGroups(false, () => {
	       	this.updateDomElements();
       	});
       	
       	this.props.fetchRetailersList(false, () => {
	       	this.updateDomElements();
       	});		
	}
	
	updateDomElements() {
				
		// CHECK IF WE HAVE BRAND DETAILS	
		if(!this.state.groups && this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id] && this.props.campaigns.campaigns[this.state.id].events && this.props.retailers.groups && this.props.retailers.retailers){
			
			if(!this.props.campaigns.campaigns[this.state.id]){
				this.props.history.push('/campaigns');
			}
			
			let campaign = this.props.campaigns.campaigns[this.state.id];
			let groups = [];
							
			if(campaign.groups && campaign.groups.length > 0){
				
				// GET USERS FROM RETAILERS FOR SELECTED GROUPS
				_.forEach(this.props.retailers.groups, (group, key) => {
					
					if(campaign.groups.includes(group.id)){
						groups.push(group);
					}			
				});
				
				let group_ids = []
			
				_.forEach(groups, (group, key) => {
					
					if(group.retailers.length > 0){
						
						_.forEach(group.retailers, (group, key) => {
							group_ids.push(group);
						});
					}
				});
											
				this.setState({
					groups: group_ids
				});
				
				this.props.usersFetch(false, group_ids);
				
			}else{
				
				// GET ALL USERS FOR ALL RETAILERS
				this.props.usersFetch();
				
				this.setState({
					groups: []
				});
			}
			
			/*
			if(group_ids.length > 0){
				this.props.usersFetch(false, group_ids);
			}else{
								
				this.props.showNotification(
					'error',
					i18n.t('campaigns:notification_deploy_error_no_retailers_title'),
					i18n.t('campaigns:notification_deploy_error_no_retailers_description')
				);
				
				this.props.history.push(`/campaigns/${this.state.id}`);
				return;
			}
			*/
			
			if(!campaign.events || Object.keys(campaign.events).length === 0){
								
				this.props.showNotification(
					'error',
					i18n.t('campaigns:notification_deploy_error_no_events_title'),
					i18n.t('campaigns:notification_deploy_error_no_events_description')
				);
				
				this.props.history.push(`/campaigns/${this.state.id}/schedule`);
				return;
			}
		}	
	}
	
	renderDistribution(type){
		
		let icon = '';
		let issues = 0;
		let sub = '';
		let total = 0;
		let types = [];
		
		switch(type){
			
			case 'website':
				icon = 'fa-images';
				types = ['slider','advert'];
				break;
				
			case 'landing':
				icon = 'fa-browser';
				types = ['landing'];
				break;
				
			case 'social':
				icon = 'fa-thumbs-up';
				types = ['twitter','facebook','instagram'];
				break;
			
			case 'email':
				icon = `fa-envelope`;
				types = ['email'];				
				break;
			
			default:
			
				break;
		}

		_.forEach(_.filter(this.props.campaigns.campaigns[this.state.id].events, (v) => _.includes(types, v.type)), (event, key) => {
						
			if(event.issues && Object.keys(event.issues).length > 0){
				++issues;
			}else{
				++total;
			}			
		});		
				
		if(issues > 0){
			sub = (
				<span className="sub">
					{i18n.t('campaigns:deploy_counter_issue', { count: issues })}
				</span>
			);
		}
		
		return (
			<div className="col-md-6 col-xl-6">
				<div className="c-card equalize stats-box">
					<span className="c-icon u-mb-small c-icon--info">
						<i className={`fal ${icon}`}></i>
					</span>
							
					<h3 className="c-text--subtitle">{i18n.t(`campaigns:settings_distribution_${type}_title`)}</h3>
					<h2 className="counter">
						<CountUp 
							delay={0} 
							duration={1} 
							end={total} 
							separator="," 
							preserveValue={true}
						/>
						
						{sub}
					</h2>
				</div>
			</div>
		)
	}
	
	renderPage() {
							
		if(!this.props.users.users || !this.state.groups || !this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id] || !this.props.campaigns.campaigns[this.state.id].events){
			return (
				<Loading />
			);
		}

		let campaign = this.props.campaigns.campaigns[this.state.id];
		let data = [];
		let retailers = 0;
		let retailersList = []
		
		if(campaign.groups && campaign.groups.length > 0){
			_.forEach(this.state.groups, (id, key) => {
				retailersList.push({id: id});
			});
			
		}else{
			retailersList = this.props.retailers.retailers;			
		}
				
		_.forEach(retailersList, (value, key) => {
						
			if(this.props.retailers.retailers && this.props.retailers.retailers[value.id]){
				
				let retailer = this.props.retailers.retailers[value.id];
				let retailerAdded = false;
				
				if(retailer.users && retailer.users.length > 0){
										
					_.forEach(retailer.users, (user, key) => {
						
						if(this.props.users.users && this.props.users.users[user]){
							
							let row = this.props.users.users[user];
							
							if(row.active == 1){
								retailerAdded = true;
																	
								data.push({
									retailer: retailer.name,
									retailer_image: retailer.image ? retailer.image : false,
									image: row.image ? row.image : false,
									forename: row.forename,
									surname: row.surname,
									email: (row.active === '1' && row.notification_email === 1) ? true : false,
									sms: (row.active === '1' && row.notification_sms === 1) ? true : false
								});
							}
						}
					});
				}
				
				if(retailerAdded){
					++retailers;
				}
			}	
		});
				
		let title = i18n.t('campaigns:deploy_title');
		
		if(campaign.date_process !== null && moment.utc(campaign.date_process.date).format('YYYY-MM-DD HH:mm:ss') > moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss')){
			title = i18n.t('campaigns:deploy_embargo_title');
		}
						
		let issues = 0;
		
		 _.forEach(campaign.events, (event, key) => {
						
			if(event.issues && Object.keys(event.issues).length > 0){
				++issues;
			}		
		});	
		
		return (
			<React.Fragment>
				{campaign.date_process !== null && moment.utc(campaign.date_process.date).format('YYYY-MM-DD HH:mm:ss') > moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss') && 
					
					<div className="c-card c-alert u-mb-medium c-alert--danger">
		                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
							<i className="fal fa-clock" style={{ color: '#FFF' }}></i>
						</span>
					
						<div className="c-alert__content">
							<h4 className="c-alert__title">{i18n.t('campaigns:deploy_title_embargo')}</h4>
							<p className="u-mb-small">{i18n.t('campaigns:deploy_embargo_description', { date: moment.utc(campaign.date_process.date).tz(this.props.account.profile.timezone).format('LLL') })}</p>
						</div>
					</div>
				}
				
				<div className="row cta-push">
					<div className="col col-md-5 col-sm-12">
												
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('campaigns:deploy_title_overview')}</h4>							
							
							
							<div className="row">
								<div className="col-md-6 col-xl-6">
									<div className="c-card equalize">
								        <span className="c-icon c-icon--info u-mb-small">
								          	<i className="fal fa-store-alt"></i>
								        </span>
							
										<h3 className="c-text--subtitle">Retailer{retailers == 1 ? '' : 's'}</h3>
										<h1>
											<CountUp 
												delay={0} 
												duration={1} 
												end={retailers} 
												separator="," 
												preserveValue={true}
											/>
										</h1>
									</div>
								</div>
								
								<div className="col-md-6 col-xl-6">
									<div className="c-card equalize">
								        <span className="c-icon c-icon--info u-mb-small">
								          <i className="fal fa-users"></i>
								        </span>
							
										<h3 className="c-text--subtitle">User{data.length == 1 ? '' : 's'}</h3>
										<h1>
											<CountUp 
												delay={0} 
												duration={1} 
												end={data.length} 
												separator="," 
												preserveValue={true}
											/>
										</h1>							
									</div>
								</div>
								
								{this.renderDistribution('website')}
								{this.renderDistribution('email')}
								{this.renderDistribution('landing')}
								{this.renderDistribution('social')}
							</div>
						</div>
					</div>
					<div className="col col-md-7 col-sm-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('campaigns:deploy_title_retailers')}</h4>
							
							{data.length > 0 && 
								<Table 
									columns={[
										{
											key: "retailer_image",
											label: "",
											format: 'image_circle',
											className: 'tight'
										},
										{
											key: "retailer",
											label: "Retailer",
											sortable: true
										},
										{
											key: "image",
											label: "",
											format: 'image_circle',
											className: 'tight'
										},
										{
											key: "user",
											label: "User",
											sortable: true
										},
										{
											key: "email",
											label: "Email",
											sortable: true,
											format: 'icon'
										},
										{
											key: "sms",
											label: "SMS",
											sortable: true,
											format: 'icon'
										}
									]}
									data={_.map(data, (row, key) => {
											
										return ({
											retailer_image: {
												src: row.retailer_image ? imageResize(row.retailer_image, 100) : false,
												icon: 'fal fa-store-alt',
												alt: row.retailer
											},
											image: {
												src: row.image ? imageResize(row.image, 100) : false,
												icon: 'fal fa-user',
												alt: `${row.forename} ${row.surname}`
											},
											retailer: row.retailer,
											user: `${row.forename} ${row.surname}`,
											email: row.email ? 'fal fa-check' : 'fal fa-times',
											sms: row.sms ? 'fal fa-check' : 'fal fa-times'							
										});
									})}
									sort={{
										column: 'retailer',
										order: 'asc'
									}}
								/>
							}
							
							{data.length == 0 && 
								<p>
									{i18n.t('campaigns:notification_deploy_error_no_retailers_description')}
								</p>
							}
						</div>
					</div>
				</div>	
								
				{(issues === 0 && data.length > 0 /*&& moment(campaign.date_start) > moment()*/) && 
					
					<div id="cta">
						<Submit
							label={i18n.t(`campaigns:deploy_submit`, { count: retailers })}
							onClick={() => {
														
								this.props.deployCampaign(this.state.id, () => {
									
									let section = 'deployed';
									
									if(campaign.date_process !== null && moment.utc(campaign.date_process.date).format('YYYY-MM-DD HH:mm:ss') > moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss')){ 
									
										this.props.showNotification(
											'success',
											i18n.t('campaigns:notification_deployed_embargo_title'),
											i18n.t('campaigns:notification_deployed_embargo_description', { date: moment.utc(campaign.date_process.date).tz(this.props.account.profile.timezone).format('LLL') })
										);
										
										section = 'deployed_pending';
										
									}else{
										this.props.showNotification(
											'success',
											i18n.t('campaigns:notification_deployed_title'),
											i18n.t('campaigns:notification_deployed_description')
										);
									}
									
									this.props.history.push(`/campaigns?type=${section}`);
								});
							}}
						/>
					</div>
				}

			</React.Fragment>
		);
	}
	
	render(){
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}

		let campaign = this.props.campaigns.campaigns[this.state.id];
				
		let title = i18n.t('campaigns:deploy_title');
		
		if(campaign.date_process !== null && moment.utc(campaign.date_process.date).format('YYYY-MM-DD HH:mm:ss') > moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss')){
			title = i18n.t('campaigns:deploy_embargo_title');
		}
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
			
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				{this.renderPage()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ campaigns, users, account, retailers }, ownProps){
	return {
		campaigns,
		users,
		retailers,
		account
	};
}

export default connect(mapStateToProps, { 
	fetchCampaignsList,
	fetchRetailersGroups,
	fetchRetailersList,
	usersFetch,
	deployCampaign,
	showNotification
})(CampaignsDeploy);
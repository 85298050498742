import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import Loading from '../../../components/chrome/loading';
import IntegrationsEmail from '../../../components/retailers/integrations/email';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import _ from 'lodash';
import Toolbar from '../../../components/chrome/toolbar';

//import "highlight.js/styles/darkula.css"

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { fetchSubscribersGroups } from '../../../actions/subscribers';
import { showNotification } from '../../../actions/notifications';
import SendDeveloper from '../../../components/retailers/send_developer';

var formName = 'formRetailersIntegrationsEmail';


class RetailersIntegrationsEmail extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.props.fetchSubscribersGroups(id, () => {
	       	this.updateDomElements();
       	});
       	
       	this.state = {
	       	id: id,
	       	empty: false,
	       	double_optin: false,
	       	name: false,
	       	dob: false,
	       	gender: false,
	       	group: []
       	}	
	}
	
	updateDomElements(){		
		
		if(this.props.retailers.retailers && this.props.subscribers.groups.local && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];

			this.setState({
				double_optin: (retailer.details.subscribe_double_optin && retailer.details.subscribe_double_optin === '1') ? true : false,
		       	name: (retailer.details.subscribe_field_name && retailer.details.subscribe_field_name === '1') ? true : false,
		       	dob: (retailer.details.subscribe_field_dob && retailer.details.subscribe_field_dob === '1') ? true : false,
		       	gender: (retailer.details.subscribe_field_gender && retailer.details.subscribe_field_gender === '1') ? true : false,
		       	group: retailer.details.subscribe_group ? JSON.parse(retailer.details.subscribe_group) : [],
			});
			
			let init = { 
				group: retailer.details.subscribe_group ? JSON.parse(retailer.details.subscribe_group) : [] 
			}
			
			if(retailer.details.email_local_email){
				init.email = retailer.details.email_local_email;
			}
						
			this.props.initialize(init);
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onInputGroupChange(selected){
				
		this.setState({
			group: selected
		});
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
	  	
	  	let groups = _.map(this.state.group, (group, key) => {
			
			if(group.value){
				return group.value
			}else{
				return group;
			}	
		})
			  	
	  	let posting = {
		  	group: groups,
			double_optin: this.state.double_optin,
			field_name: this.state.name,
			field_dob: this.state.dob,
			field_gender: this.state.gender,
			email: values.email
	  	}
		  	
		this.props.saveRetailerIntegration(formName, 'email_local', posting, () => {
			
			this.props.showNotification(
				'success',
				i18n.t('retailers:notification_integration_email_local_updated_title'),
				i18n.t('retailers:notification_integration_email_local_updated_description')
			);
				
			this.props.history.push(this.props.parentPath);
	  	});
	}
	
	onInputChange(event, type){
				
		this.setState({
			[type]: event.target.checked
		});
	}

	render() {
			
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
				
		const { handleSubmit } = this.props;
		
		let retailer = retailers.retailers[this.state.id];

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_email_local_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:integration_email_local_toolbar_settings'),
						url: `${this.props.parentPath}/integrations/email`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('retailers:integration_email_local_action_save'),
						submit: formName
					}]}
				/>
				
				<div className="row">
					
					<div className="col-xl-6">
												
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_subscription_title')}</h4>
							
							<Field
								label={i18n.t('retailers:integration_email_local_label_group', { count: this.state.group ? this.state.group.length : 1 })} 
								name="group"
								type="suggest"
								multiple={true}
								options={_.map(this.props.subscribers.groups.local, (group, key) => {
																					
									return ({
										label: group.name,
										value: group.id							
									});
								})}
								selected={this.state.group}
								onChangeFunc={this.onInputGroupChange.bind(this)}
								component={InputField}
								className="u-mb-medium"
							/>
															
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_double_optin')} 
								name="double_optin"
								type="switch"
								selected={this.state.double_optin}
								onChangeFunc={(e) => { this.onInputChange(e, 'double_optin') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_name')} 
								name="field_name"
								type="switch"
								selected={this.state.name}
								onChangeFunc={(e) => { this.onInputChange(e, 'name') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_dob')} 
								name="field_dob"
								type="switch"
								selected={this.state.dob}
								onChangeFunc={(e) => { this.onInputChange(e, 'dob') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_gender')} 
								name="field_gender"
								type="switch"
								selected={this.state.gender}
								onChangeFunc={(e) => { this.onInputChange(e, 'gender') }}
								component={InputField}
							/>
							
						</div>
												
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_seding_title')}</h4>
							
							<Field
								label={i18n.t('retailers:integration_email_local_settings_label_email')}
								labelSub={i18n.t('retailers:integration_email_local_settings_label_email_info', {email: retailer.details.email})}
								name="email"
								type="text"
								component={InputField}
							/>
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_instructions_title')}</h4>
						
							<IntegrationsEmail 
								retailer={retailer}
							/>
																
							<br/>
							<SendDeveloper />
						</div>
					</div>					
				</div>
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if (!values.group) {
		errors.group = 'Enter at least one group for where users should be saved!';
	}

	return errors;
}

function mapStateToProps({ account, retailers, subscribers }, ownProps){
	return {
		account,
		retailers,
		subscribers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		showNotification,
		fetchRetailersList,
		saveRetailerIntegration,
		fetchSubscribersGroups
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsEmail)
);

export const campaignsConstants = {
    CAMPAIGN_LIST: 'CAMPAIGN_LIST',
    CAMPAIGN_SAVE_GENERAL: 'CAMPAIGN_SAVE_GENERAL',
    CAMPAIGN_SAVE_ASSETS: 'CAMPAIGN_SAVE_ASSETS',
    CAMPAIGN_SAVE_EVENT: 'CAMPAIGN_SAVE_EVENT',
    CAMPAIGN_IMPORT_LIST: 'CAMPAIGN_IMPORT_LIST',
    CAMPAIGN_IMPORT: 'CAMPAIGN_IMPORT',
    CAMPAIGN_DEPLOY: 'CAMPAIGN_DEPLOY',
    CAMPAIGN_REGENERATE_EVENTS: 'CAMPAIGN_REGENERATE_EVENTS',
    CAMPAIGN_DELETE_EVENT: 'CAMPAIGN_DELETE_EVENT',
    CAMPAIGN_LAUNCH: 'CAMPAIGN_LAUNCH',
    CAMPAIGN_DELETE: 'CAMPAIGN_DELETE',
    CAMPAIGN_SAVE_WARNINGS: 'CAMPAIGN_SAVE_WARNINGS'
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';

import { RouteProtected} from './protection';

import Layout from '../components/chrome/layout';
import ModalWrapper from '../components/chrome/overlay';
import GAModal from '../components/tracking/ga_modal';
import Loading from '../components/chrome/loading';

import Error404 from '../components/errors/404';
import Dashboard from '../containers/dashboard';

import Assets from '../containers/assets';
import AssetsView from '../containers/assets/view';
import AssetsEdit from '../containers/assets/edit';

import Library from '../containers/library';

import Products from '../containers/products';
import ProductsEdit from '../containers/products/edit';
import ProductsImport from '../containers/products/import';

import Analytics from '../containers/analytics';

import Subscribers from '../containers/subscribers';
import SubscribersGroup from '../containers/subscribers/edit';
import SubscribersUsers from '../containers/subscribers/users';
import SubscribersUser from '../containers/subscribers/user';
import SubscribersHistory from '../containers/subscribers/history';
import SubscribersImport from '../containers/subscribers/import';

import Collections from '../containers/collections';
import CollectionsEdit from '../containers/collections/edit';

import Campaigns from '../containers/campaigns';
import CampaignsSetup from '../containers/campaigns/setup';
import CampaignsAssets from '../containers/campaigns/assets';
import CampaignsSchedule from '../containers/campaigns/schedule';
import CampaignsScheduleEvent from '../containers/campaigns/event';
import CampaignsAnalytics from '../containers/campaigns/analytics';
import CampaignsDeploy from '../containers/campaigns/deploy';
import CampaignsWarnings from '../containers/campaigns/warnings';

import Articles from '../containers/articles';
import ArticlesEdit from '../containers/articles/edit';

import Retailers from '../containers/retailers';
import RetailersSettings from '../containers/retailers/details/settings';
import RetailersBranding from '../containers/retailers/details/branding';
import RetailersIntegrations from '../containers/retailers/details/integrations';
import RetailersUsers from '../containers/retailers/details/users';

import RetailersIntegrationsMailchimp from '../containers/retailers/integrations/mailchimp';
import RetailersIntegrationsEmail from '../containers/retailers/integrations/email';
import RetailersIntegrationsSlider from '../containers/retailers/integrations/slider';
import RetailersIntegrationsAdvert from '../containers/retailers/integrations/advert';
import RetailersIntegrationsDomain from '../containers/retailers/integrations/domain';
import RetailersIntegrationsCode from '../containers/retailers/integrations/code';
import RetailersIntegrationsApi from '../containers/retailers/integrations/api';
import RetailersIntegrationsConversion from '../containers/retailers/integrations/conversion';
import RetailersIntegrationsDeveloper from '../containers/retailers/integrations/developer';

import RetailersGroups from '../containers/retailers/groups/index';
import RetailersGroupsGroup from '../containers/retailers/groups/edit';
import RetailersGroupsAssigned from '../containers/retailers/groups/assigned';

import EditUser from '../containers/users/user';

import Regional from '../containers/regional';
import RegionalSettings from '../containers/regional/edit';
import RegionalUsers from '../containers/regional/users';

import Account from '../containers/account';
import AccountPreferences from '../containers/account/preferences';

import { userAccount } from '../actions/account';
import { IntercomAPI } from 'react-intercom';
import moment from 'moment';

class RoutesTemplated extends Component {
	
	constructor(props) {
		
		super(props);
						
		if(!localStorage.getItem('user_id')) {
			this.props.history.push('/logout');
		}
				
		this.state = {
			display: false
		}
		
		this.props.userAccount();
	}
	
	/*componentWillReceiveProps(nextProps) {
				
		if(!this.state.display && nextProps.account.permissions !== false){
			
			this.setState({
				display: true
			});	
		}	
	}*/
	
	componentDidUpdate(nextProps) {
		
		if(!this.state.display && this.props.account.permissions !== false){

			// SET INTERCOM DETAILS
			IntercomAPI('update', {
				app_id: process.env.REACT_APP_INTERCOM_APP_ID,
				email: this.props.account.profile.email,
				user_id: this.props.account.profile.id,
				created_at: moment(this.props.account.profile.created.date).valueOf()
			});
			
			this.setState({
				display: true
			});	
		}else{
			IntercomAPI('update', {last_request_at: parseInt((new Date()).getTime()/1000)});
		}
	}
	
	render() {
		
		if(!this.state.display){
			
			return (
				<Loading />
			);
		}
		
		return (
			<React.Fragment>
				<Layout>
					<Switch>
						
						<RouteProtected path="/assets" component={Assets} />
						
						<RouteProtected path="/articles" component={Articles} />
						
						
						<RouteProtected path="/campaigns/:id(\d+)/analytics" component={CampaignsAnalytics} />
						<RouteProtected path="/campaigns/:id(\d+)/schedule" component={CampaignsSchedule} />
						<RouteProtected path="/campaigns/:id(\d+)/assets" component={CampaignsAssets} />
						<RouteProtected path="/campaigns/:id(\d+)" component={CampaignsSetup} />
						<RouteProtected path="/campaigns/add" component={CampaignsSetup} />
						<RouteProtected path="/campaigns" component={Campaigns} />
						
						<RouteProtected path="/settings/users" component={RetailersUsers} />
						<RouteProtected path="/settings/integrations" component={RetailersIntegrations} />
						<RouteProtected path="/settings/branding" component={RetailersBranding} />
						<RouteProtected path="/settings" component={RetailersSettings} />
						
						<RouteProtected path="/retailers/groups" component={RetailersGroups} />
						<RouteProtected path="/retailers" component={Retailers} />
						
						<RouteProtected path="/regional" component={Regional} />
						
						<RouteProtected path="/products" component={Products} />
						
						<RouteProtected path="/subscribers" component={Subscribers} />
						
						<RouteProtected path="/analytics" component={Analytics} />
						
						
						<RouteProtected path="/account/preferences" component={AccountPreferences} />
						<RouteProtected path="/account" component={Account} />
												
						<RouteProtected exact path="/" component={Dashboard} />
						
						{/* NO MATCH, SHOW 404 */}
						<Route path="*" component={Error404} /> 
					</Switch> 
				</Layout>
				<Switch>
								
					{/* MODALS */}
					<ModalRoute path="*/library/:type(\w+)/:id(\d+)" component={GAModal(Library)} />
					
					<ModalRoute path="*/warnings/:id(\d+)" component={GAModal(CampaignsWarnings)} />
					
					<ModalRoute path="*/event/:campaign(\d+)/:id(\d+)" component={GAModal(CampaignsScheduleEvent)} />
					<ModalRoute path="*/event/:campaign(\d+)" component={GAModal(CampaignsScheduleEvent)} />
					<ModalRoute path="*/event" component={GAModal(CampaignsScheduleEvent)} />
					
					<ModalRoute path="*/collection/:id(\d+)/edit" component={GAModal(CollectionsEdit)} />
					<ModalRoute path="*/collection/:id(\d+)" component={GAModal(Collections)} />
					<ModalRoute path="*/collection/add" component={GAModal(CollectionsEdit)} />
					
					<ModalRoute path="*/article/:id(\d+)" component={GAModal(ArticlesEdit)} />
					<ModalRoute path="*/article" component={GAModal((ArticlesEdit))} />
					
					<ModalRoute path="*/asset/:id(\d+)/edit" component={GAModal(AssetsEdit)} />
					<ModalRoute path="*/asset/:id(\d+)" component={GAModal(AssetsView)} />
					<ModalRoute path="*/asset" component={GAModal(AssetsEdit)} />	
					
					<ModalRoute path="*/sku" component={GAModal(ProductsImport)} />
					<ModalRoute path="*/product/:id(\d+)" component={GAModal(ProductsEdit)} />
					<ModalRoute path="*/product" component={GAModal(ProductsEdit)} />
					
					<ModalRoute path="*/group/:id(\d+)/assigned" component={GAModal(RetailersGroupsAssigned)} />
					<ModalRoute path="*/group/:id(\d+)" component={GAModal(RetailersGroupsGroup)} />
					<ModalRoute path="*/group/add" component={GAModal(RetailersGroupsGroup)} />
					
					<ModalRoute path="*/mailchimp" component={GAModal(RetailersIntegrationsMailchimp)} />
					<ModalRoute path="*/email" component={GAModal(RetailersIntegrationsEmail)} />
					<ModalRoute path="*/slider" component={GAModal(RetailersIntegrationsSlider)} />
					<ModalRoute path="*/advert" component={GAModal(RetailersIntegrationsAdvert)} />
					<ModalRoute path="*/domain" component={GAModal(RetailersIntegrationsDomain)} />
					<ModalRoute path="*/code" component={GAModal(RetailersIntegrationsCode)} />	
					<ModalRoute path="*/api" component={GAModal(RetailersIntegrationsApi)} />
					<ModalRoute path="*/conversion" component={GAModal(RetailersIntegrationsConversion)} />
					<ModalRoute path="*/developer" component={GAModal(RetailersIntegrationsDeveloper)} />
					
					<ModalRoute path="*/audience/:id(\d+)/import" component={GAModal(SubscribersImport)} />
					<ModalRoute path="*/audience/:id(\d+)/users/:user(\d+)/history" component={GAModal(SubscribersHistory)} />
					<ModalRoute path="*/audience/:id(\d+)/users/:user(\d+)" component={GAModal(SubscribersUser)} />
					<ModalRoute path="*/audience/:id(\d+)/users/add" component={GAModal(SubscribersUser)} />
					<ModalRoute path="*/audience/:id(\d+)/users" component={GAModal(SubscribersUsers)} />
					<ModalRoute path="*/audience/:id(\d+)" component={GAModal(SubscribersGroup)} />
					<ModalRoute path="*/audience" component={GAModal(SubscribersGroup)} />
					
					<ModalRoute path="*/user/:type/:id(\d+)" component={GAModal(EditUser)} />
					<ModalRoute path="*/user/:type" component={GAModal(EditUser)} />	
					
					<ModalRoute path="*/retailer/:id(\d+)/users" component={GAModal(RetailersUsers)} />
					<ModalRoute path="*/retailer/:id(\d+)/integrations" component={GAModal(RetailersIntegrations)} />
					<ModalRoute path="*/retailer/:id(\d+)/branding" component={GAModal(RetailersBranding)} />
					<ModalRoute path="*/retailer/:id" component={GAModal(RetailersSettings)} />
					<ModalRoute path="*/retailer/add" component={GAModal(RetailersSettings)} />
					
					<ModalRoute path="*/region/:id(\d+)/users" component={GAModal(RegionalUsers)} />
					<ModalRoute path="*/region/:id" component={GAModal(RegionalSettings)} />
					<ModalRoute path="*/region/add" component={GAModal(RegionalSettings)} />					
					
					<ModalRoute path="*/deploy/:id(\d+)" component={GAModal(CampaignsDeploy)} />
					
										
					
					
					
				</Switch>
				<ModalWrapper />
			</React.Fragment>
		)
	}
}

function mapStateToProps({ account }, ownProps) {
	return {
		account
	 };
}

export default connect(mapStateToProps, { 
	userAccount
})(RoutesTemplated);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionOptions } from '../../actions/chrome';
import i18n from '../../helpers/i18n'; 
import { checkPermission } from '../../helpers/permissions';
import CountUp from 'react-countup';
import _ from 'lodash';
import Table from '../../components/chrome/table';
import Toolbar from '../../components/chrome/toolbar';
import { fetchSubscribersGroups, fetchSubscribersStats } from '../../actions/subscribers';
import EmptySection from '../../components/chrome/empty';
import Loading from '../../components/chrome/loading';
import moment from 'moment';

class Subscribers extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}
		
		this.props.fetchSubscribersStats(
			moment().subtract(90, 'day').format('YYYY-MM-DD'),
			moment().format('YYYY-MM-DD')
		);
		
		this.props.fetchSubscribersGroups();
		this.props.setSectionOptions('subscribers', i18n.t('subscribers:title'));
		
		this.state = {
			search: false
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	renderOverview(){
				
		let totals = {
			total: 0,
			subs: 0,
			unsubs: 0
		};
				
		_.forEach(this.props.subscribers.overview, (group, key) => {
					
			if(group.total){
				totals.total = totals.total + parseFloat(group.total);
			}
			
			if(group.subs){
				totals.subs = totals.subs + parseFloat(group.subs);
			}
			
			if(group.unsubs){
				totals.unsubs = totals.unsubs + parseFloat(group.unsubs);
			}
		});

		let title = '';
		let icon = '';
		let className = '';
		let sub = '';
				
		return _.map(totals, (value, key) => {
			
			sub = '';
									
			switch(key){
				
				case 'total':
					title = i18n.t('subscribers:overview_label_total');
					icon = 'users';
					className = 'c-icon--info';
					break;

				case 'subs':
					title = i18n.t('subscribers:overview_label_subs', { days: 90 });
					icon = 'user-plus';
					className = 'c-icon--success';
					break;
					
				case 'unsubs':
					title = i18n.t('subscribers:overview_label_unsubs', { days: 90 });
					icon = 'user-minus';
					className = 'c-icon--danger';
					break;
					
				default:
				
					break;
			}																		
							
			return (
				<div className="col-md-4 col-xl-4" key={key}>
					<div className="c-card equalize stats-box">
						<span className={`c-icon u-mb-small ${className}`}>
							<i className={`fal fa-${icon}`}></i>
						</span>
								
						<h3 className="c-text--subtitle">{title}</h3>
						<h2 className="counter">
							<CountUp 
								delay={0} 
								duration={1} 
								end={value} 
								separator="," 
								preserveValue={true}
							/>
							
							{sub}
						</h2>
					</div>
				</div>
			)
		});
	}
		
	render() {
		
		let { subscribers } = this.props;

		if(_.isEmpty(subscribers.groups) || !subscribers.overview){
			return (
				<Loading />
			);
		}
		
		let filter = false;
		let groups = {};
		
		_.map(subscribers.groups, (group, key) => {
			return _.assign(groups, _.mapKeys(_.map(group, o => _.extend({source: key}, o)), 'id'));
		});
		
		if(!_.isEmpty(groups)){
			
			/*filter = {
				toggleFunc: (status) => {
					this.setState({
						search: status
					});
				},
				onChangeFunc: (event) => { 
					
					this.setState({
						keyword: event.target.value
					})
				},
				fields: [
					{
						placeholder: i18n.t('subscribers:filter_keyword'),
						name: "keyword",
						type: 'text'
					}
				],
				open: this.state.search
			}*/
		}
		
		let toolbar = (
			<Toolbar
				buttons={[
					{
						label: i18n.t('subscribers:action_add'),
						url: '/subscribers/audience'
					}
				]}
				filter={filter}
			/>
		)
	
		if(_.isEmpty(groups)){
			
			return (
				<React.Fragment>
				
					{toolbar}
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('subscribers:empty_title')}
						description={i18n.t('subscribers:empty_description')}
						cta={{
							label: i18n.t('subscribers:action_add'),
							url: `${this.props.location.pathname}/audience`
						}}
					/>
				</React.Fragment>
			);			
		}
		
		return (
			<React.Fragment>
				{toolbar}
				<div className="container">
					<div className="c-card u-pb-zero">
						<h4 className="u-mb-medium">
			         		{i18n.t('subscribers:title_overview')}
			         	</h4>	
						<div className="row">
							{this.renderOverview()}
						</div>
						
					</div>
					<div className="c-card">
						
						<Table 
							columns={[
								{
									key: "icon",
									label: "",
									format: 'icon',
									className: 'tight'
								},
								{
									key: "name",
									label: "Name",
									sortable: true
								},
								{
									key: "subscribers",
									label: "Subscribers",
									sortable: true
								},
								{
									key: "actions",
									label: "Actions",
									className: "tight right"
								}
							]}
							data={_.map(groups, (group, key) => {
								
								let icon = 'fal fa-at';	
								let count = 0;
								let actions = 'edit_local';
								
								if(group.url){
									actions = 'edit_remote';
								}
								
								if(group.subscribers){
																		
									if(Array.isArray(group.subscribers)){
										count = group.subscribers.length;
									}else{
										count = group.subscribers;
									}
								}
								
								if(group.source){
									
									switch(group.source){
										
										case 'mailchimp':
											icon = 'fab fa-mailchimp';
											break;
											
										default: 
										
											break;
									}
								}
																									
								return ({
									id: group.id,
									name: group.name,
									icon: icon,
									subscribers: count,
									actions: [actions]
								});
							})}
							actions={{
								edit_local: {
									tooltip: i18n.t('retailers:sub_action_edit'),
									icon: 'fal fa-edit',
									url: '/subscribers/audience/[ID]'
								},
								edit_remote: {
									tooltip: i18n.t('retailers:sub_action_edit'),
									icon: 'fal fa-external-link',
									onClick: (id) => {
										window.open(groups[id].url);
									}
								},
							}}
							sort={{
								column: 'name',
								order: 'asc'
							}}
							rowClick={(row) => {
								
								if(groups[row.id].url){
									window.open(groups[row.id].url);
								}else{
									this.props.history.push(`/subscribers/audience/${row.id}`);
								}
							}}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, subscribers }, ownProps){
	return {
		account, 
		subscribers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchSubscribersGroups,
	fetchSubscribersStats
})(Subscribers);
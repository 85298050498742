export const subscribersConstants = {
    SUBSCRIBERS_GROUPS: 'SUBSCRIBERS_GROUPS',
    SUBSCRIBERS_GROUPS_SAVE: 'SUBSCRIBERS_GROUPS_SAVE',
    SUBSCRIBERS_USERS: 'SUBSCRIBERS_USERS',
    SUBSCRIBERS_USER_SAVE: 'SUBSCRIBERS_USER_SAVE',
    SUBSCRIBERS_STATS: 'SUBSCRIBERS_STATS',
    SUBSCRIBERS_USERS_IMPORT: 'SUBSCRIBERS_USERS_IMPORT',
    SUBSCRIBERS_HISTORY: 'SUBSCRIBERS_HISTORY',
    SUBSCRIBERS_UNSUBSCRIBE: 'SUBSCRIBERS_UNSUBSCRIBE'
};


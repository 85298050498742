import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../../components/chrome/toolbar';
import i18n from '../../../helpers/i18n'; 
import { generateBack } from '../../../helpers/back';

import { checkPermission } from '../../../helpers/permissions';

class RetailsDetailsToolbar extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			search: false
		}
	}
	
	render() {
		
		let buttons = [];
		let filter = false;
		let tabs = [];
		
		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('retailers:settings_submit'),
					submit: this.props.formName
				});
				break;
				
			case 'branding':
				buttons.push({
					label: i18n.t('retailers:branding_submit'),
					submit: this.props.formName
				});
				break;
				
			case 'integrations':
				buttons.push({
					label: i18n.t('retailers:action_send_developer'),
					url: '/settings/integrations/developer'
				});
				break;
				
			case 'users':
				buttons.push({
					label: i18n.t('retailers:action_add_user'),
					url: this.props.own ? '/settings/users/user/retailer' : `${this.props.parentPath}/user/retailer?id=${this.props.id}&b=${generateBack(window.location.pathname)}`
				});
				
				/*filter = {
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('retailers:filter_users_status'),
							name: "status",
							type: 'suggest',
							options: [
								{
									value: '',
									label: i18n.t('retailers:filter_users_status_label_all')
								},
								{
									value: 1,
									label: i18n.t('retailers:filter_users_status_label_active')
								},
								{
									value: 0,
									label: i18n.t('retailers:filter_users_status_label_invited')
								}
							]
						},
						{
							placeholder: i18n.t('retailers:filter_users_keyword'),
							name: "keyword",
							type: 'text'
						}
					],
					open: this.state.search
				}*/
				break;
				
				
			default:
			
				break;			
		}
		
		let url = '/settings';
		
		if(!this.props.own){
			url = `${this.props.parentPath}/retailer/${this.props.id}`;
		}
		
		tabs.push({
			label: i18n.t('retailers:toolbar_setting'),
			url: url,
			selected: this.props.section === 'settings' ? true : false
		});
		
		if(this.props.id || this.props.own){
			if(checkPermission(this.props.account.permissions, 'RETAILERS_BRANDING')){
				tabs.push({
					label: i18n.t('retailers:toolbar_branding'),
					url: `${url}/branding`,
					selected: this.props.section === 'branding' ? true : false
				});
			}
			
			if(checkPermission(this.props.account.permissions, 'RETAILERS_INTEGRATIONS')){
				tabs.push({
					label: i18n.t('retailers:toolbar_integrations'),
					url: `${url}/integrations`,
					selected: this.props.section === 'integrations' ? true : false
				});
			}
			
			if(checkPermission(this.props.account.permissions, 'RETAILERS_USERS')){
				tabs.push({
					label: i18n.t('retailers:toolbar_users'),
					url: `${url}/users`,
					selected: this.props.section === 'users' ? true : false
				});
			}
		}

		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 

})(RetailsDetailsToolbar);
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import { imageUrl } from '../../../helpers/s3';

import Loading from '../../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import _ from 'lodash';
import OverlaySettings from '../../../components/chrome/overlay_settings';
import TemplatePreview from '../../../components/templates/preview';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { fetchTemplates } from '../../../actions/templates';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import * as Doka from '../../../packages/react-doka/lib/doka.esm.min';
import '../../../packages/react-doka/lib/doka.min.css';

import { checkPermission } from '../../../helpers/permissions';
import { fetchRetailersList, saveRetailer } from '../../../actions/retailers';
import { userAccount } from '../../../actions/account';
import { fetchFonts } from '../../../actions/options';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';


registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var formName = 'formRetailersBranding';

class RetailersBranding extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;
		let own = false;

		if(!id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
				this.props.history.push('/');
			}
			
			own = true;
			id = this.props.account.group.id;
			this.props.setSectionOptions('settings', i18n.t('users:settings_title'));
		}else{
			
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_EDIT')){
				this.props.history.push('/');
			}
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
			
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				
				if(own){
					this.props.history.push('/settings');
				}else{
					this.props.history.push('/retailers');
				}
				
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.props.fetchTemplates(false, () => {
			this.updateDomElements();
		});
		
		this.props.fetchFonts(() => {
			this.updateDomElements();
		});
       			
		this.state = {
			own: own,
			id: id,
			files: {
				logo: [],
				icon: []
			},
			files_changed: {
				logo: false,
				icon: false
			},
			font: {
				text: 1,
				heading: 1
			},
			color: {
				primary: '#000000',
				secondary: '#FFFFFF',
				background: '#FFFFFF',
				text: '#333333'
			},
			preview: {
				color_primary: '#000000',
				color_secondary: '#FFFFFF',
				color_background: '#FFFFFF',
				color_text: '#333333',
				font_text: false,
				font_heading: false
			},
			links: 'general',
			footer: [],
			view: 2
		}
		
		this.updateDomElements();
		
		this.updatePreviewBouncer = _.debounce((data) => {
			this.setState({
				preview: data
			});
		}, 250);
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.props.options.fonts && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];			
			
			let files = [{
				logo: [],
				icon: []
			}];
			
			if(retailer.image){
				
				files.icon = [
					{
						source: imageUrl(retailer.image),
						options: {
			              	type: 'local'
			            }
					}
				];
			}
			
			if(retailer.details.logo){
				
				files.logo = [
					{
						source: imageUrl(retailer.details.logo),
						options: {
			              	type: 'local'
			            }
					}
				];
			}
						
			let hub = retailer.domains.hub;
			
			if(retailer.domains.custom && retailer.domains.custom.connected){
				hub = retailer.domains.custom.domain;
			}
			
			hub = `https://${hub}`;
			
			let init = retailer.details;
			let footer = [];
			
			if(retailer.details.url_footer){
				footer = JSON.parse(retailer.details.url_footer);
				
				if(typeof footer === 'object'){
					_.forEach(footer, (item, key) => {					
						init[`footer_label_${key}`] = item.label;
						init[`footer_url_${key}`] = item.url;
					});
				}else{
					footer = [];
				}
			}
									
			this.setState({
				files: files,
				files_changed: {
					logo: false,
					icon: false
				},
				color: {
					primary: retailer.details.color_primary,
					secondary: retailer.details.color_secondary,
					background: retailer.details.color_background,
					text: retailer.details.color_text,
				},
				font: {
					text: retailer.details.font_text,
					heading: retailer.details.font_heading,
				},
				preview: {
					name: retailer.name,
					url_cta: '#',
					url_hub: hub,
					url_unsubscribe: `${hub}/unsubsribe`,
					url_website: retailer.details.url_website ? retailer.details.url_website : false,
					url_facebook: retailer.details.url_facebook ? retailer.details.url_facebook : false,
					url_instagram: retailer.details.url_instagram ? retailer.details.url_instagram : false,
					url_twitter: retailer.details.url_twitter ? retailer.details.url_twitter : false,
					url_privacy: retailer.details.url_privacy ? retailer.details.url_privacy : false,
					url_footer: footer,
					color_primary: retailer.details.color_primary,
					color_secondary: retailer.details.color_secondary,
					color_background: retailer.details.color_background,
					color_text: retailer.details.color_text,
					font_text: this.props.options.fonts[retailer.details.font_text],
					font_heading: this.props.options.fonts[retailer.details.font_heading],
					telephone: retailer.details.telephone ? retailer.details.telephone : false
				},
				footer: footer
			});	
			
			this.props.initialize(init);
		}
		
		if(this.props.templates.templates){
			
			let tmp = _.filter(this.props.templates.templates, { 'type': 'email', 'type_default': 1 });			

			this.setState({
				template: tmp[0].id
			})
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	handleResize(){
		
		let current = this.state.view;
		let width = window.innerWidth;
		let view = 1
		
		if(width >= 600){
			view = 2;
		}else if(current !== false){
			view = 1;
		}
				
		this.setState({
			view: view
		});
	}
	
	footerAddRemove(type, key){
		
		let footer = this.state.footer;
						
		if(type === 'add'){
			footer.push({
				label: '',
				url: ''
			});
		}else{
			footer.splice(key, 1);
		}
								
		this.setState({
			footer: footer
		});
		
		_.forEach(footer, (item, key) => {					
			this.props.change(`footer_label_${key}`, item.label);
			this.props.change(`footer_url_${key}`, item.url);
		});	
	}
	
	onInputChangeFooter(type, key, value){

		let footer = this.state.footer;

		if(!footer[key]){
			footer[key] = {
				label: '',
				url: ''
			};
		}
		
		footer[key][type] = value.target.value;
		
		this.setState({
			footer: footer
		});
		
		this.updatePreviewData(`url_footer`, footer);
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);
	  	let posting = _.clone(values);
	  	
	  	posting.color_primary = this.state.color.primary;
		posting.color_secondary = this.state.color.secondary;
		posting.color_background = this.state.color.background;
		posting.color_text = this.state.color.text;
		
		posting.font_text = this.state.font.text;
		posting.font_heading = this.state.font.heading;
		posting.url_footer = this.state.footer;
			
	  	let files = [];
	  	
	  	if(this.state.files_changed.logo){
		  	
		  	files = _.map(this.logo.getFiles(), (file, key) => {
		  	
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
			
			if(files.length > 0){
				posting.logo = files[0].encoded;
			}else {
				posting.logo = 'none';
			}
		}else if(posting.logo){
			delete(posting.logo)
		}
		
		if(this.state.files_changed.icon){
		  	
		  	files = _.map(this.icon.getFiles(), (file, key) => {
		  	
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
			
			if(files.length > 0){
				posting.icon = files[0].encoded;
			}else {
				posting.icon = 'none';
			}
		}else if(posting.icon){
			delete(posting.icon)
		}
		
		this.props.saveRetailer(formName, this.state.id, posting, (id) => {
			
			if(this.state.own){
				this.props.userAccount();
			}
			
			this.setState({
				files_changed: {
					logo: false,
					icon: false
				}
			});
			
			//this.props.history.push(`/retailers/${id}/branding`);		
		});
	}
	
	toggleLinksType(type){
		
		this.setState({
			links: type
		});
	}
	
	onColorChange(type, color){
				
		let existing = this.state.color;
		existing[type] = color;
	
		this.setState({ 
			color: existing 
		});
		
		this.updatePreviewData(`color_${type}`, color);
	};
	
	onFontChange(type, selected){
		
		let existing = this.state.font;
		existing[type] = selected.value;
	
		this.setState({ 
			font: existing 
		});
						
		this.updatePreviewData(`font_${type}`, this.props.options.fonts[selected.value]);
	};
	
	onInputTemplateChange(selected){
				
		this.setState({
			template: selected.value
		});
	}
	
	updatePreviewData(type, value){
				
		let data = this.state.preview;
		
		if(value.target){
			data[type] = value.target.value;
		}else{
			data[type] = value;
		}
					
		this.updatePreviewBouncer(data);	
	}
	
	generateExampleData(template){
		
		let example = [];
		let settings = JSON.parse(this.props.templates.templates[template].settings);
		let preload = 1;
		let i = 1;
		
		_.forEach(settings.layout, (layout, key) => {
			
			let section = settings.sections[layout.section];
			
			let sectionPush = {
				'section': layout.section,
				'data': {}
			};	
												
			_.forEach(section.blocks, (blocks, key2) => {
									
				sectionPush.data[key2] = {}					
										
				_.forEach(blocks, (block, key3) => {
					
					let block_settings = block;
					
					if(layout.blocks && layout.blocks[key2]){
																								
						_.forEach(layout.blocks[key2][key3], (value4, key4) => {
							block_settings[key4] = value4;
						});
					}
					
					switch(block_settings.type){
						
						case 'template':
							sectionPush.data[key2][key3] = {
								data: {sections: this.generateExampleData(block.source)},
								template: this.props.templates.templates[block.source]
							};
							break;
							
						default:
							switch(block.source){
				
								case 'public_name':
									sectionPush.data[key2][key3] = i18n.t('campaigns:template_example_public_name');
									break;
									
								case 'public_subline':
									sectionPush.data[key2][key3] = i18n.t('campaigns:template_example_subline');
									break;
									
								case 'description':
									sectionPush.data[key2][key3] = i18n.t('campaigns:template_example_content');
									break;
									
								case 'images_headline':
								case 'images_secondary':
								
									if(block.type === 'repeat'){
																		
										if(!sectionPush.data[key2][key3] || sectionPush.data[key2][key3] === ''){
											sectionPush.data[key2][key3] = [];
										}
										
										preload = 1;
										i = 1;
										
										if(block_settings.preload){
											preload = block_settings.preload;
										}
											
										while(i <= preload){
											sectionPush.data[key2][key3].push({
												image: `brands/${this.props.brand.details.directory}/placeholders/image.jpg`,
												imagePlaceholder: i18n.t('campaigns:template_example_image_watermark')
											});
											
											++i;
										}	
										
									}else{
										sectionPush.data[key2][key3] = {
											image: `brands/${this.props.brand.details.directory}/placeholders/image.jpg`,
											imagePlaceholder: i18n.t('campaigns:template_example_image_watermark')
										};
									}
									break;
									
								case 'videos':
									sectionPush.data[key2][key3] = {
										thumbnail: `brands/${this.props.brand.details.directory}/placeholders/image.jpg`,
										imagePlaceholder: i18n.t('campaigns:template_example_video_watermark'),
										url: 'https://www.youtube.com/watch?v=VTT9Hp3WBJg',
										page: ''
									};
									break;
									
								case 'products':
									
									if(!sectionPush.data[key2][key3] || sectionPush.data[key2][key3] === ''){
										sectionPush.data[key2][key3] = [];
									}
									
									preload = 1;
									i = 1;
									
									if(block_settings.preload){
										preload = block_settings.preload;
									}
										
									while(i <= preload){
										sectionPush.data[key2][key3].push({
											image: `brands/${this.props.brand.details.directory}/placeholders/product.jpg`,
											imagePlaceholder: i18n.t('campaigns:template_example_product_watermark'),
											title: i18n.t('campaigns:template_example_product_title'),
											description: i18n.t('campaigns:template_example_product_description'),
											price: '123.00',
											url: '/'
										});
										
										++i;
									}						
									break;
									
								case 'articles':
									
									if(!sectionPush.data[key2][key3] || sectionPush.data[key2][key3] === ''){
										sectionPush.data[key2][key3] = [];
									}
									
									preload = 1;
									i = 1;
									
									if(block_settings.preload){
										preload = block_settings.preload;
									}
										
									while(i <= preload){
										sectionPush.data[key2][key3].push({
											image: `brands/${this.props.brand.details.directory}/placeholders/product.jpg`,
											imagePlaceholder: i18n.t('campaigns:template_example_article_watermark'),
											title: i18n.t('campaigns:template_example_article_title'),
											summary: i18n.t('campaigns:template_example_article_summary'),
											content: i18n.t('campaigns:template_example_article_content'),
											publish: new Date(),
											url: '/'
										});
										
										++i;
									}						
									break;
									
								case 'article':
									sectionPush.data[key2][key3] = {
										image: `brands/${this.props.brand.details.directory}/placeholders/product.jpg`,
										imagePlaceholder: i18n.t('campaigns:template_example_article_watermark'),
										title: i18n.t('campaigns:template_example_article_title'),
										summary: i18n.t('campaigns:template_example_article_summary'),
										content: i18n.t('campaigns:template_example_article_content'),
										publish: new Date(),
										url: '/'
									};				
									break;
									
								case 'preset':
									sectionPush.data[key2][key3] = block_settings.value;
									break;
									
								default:
								
									break;
							}
							break;
					}					
				});
			});
			
			example.push(sectionPush);
		});
	
		return example;
	}
	
	renderPage(){
				
		if(!this.props.templates.templates || !this.state.template){
						
			return (
				<Loading />
			);
		}
		
		let templates = {
			email: _.filter(this.props.templates.templates, { 'type': 'email' }),
			landing: _.filter(this.props.templates.templates, { 'type': 'landing' }),
			slider: _.filter(this.props.templates.templates, { 'type': 'slider' }),
			advert: _.filter(this.props.templates.templates, { 'type': 'advert' }),
			hub: _.filter(this.props.templates.templates, (o) => _.includes(['hub_index','hub_articles','hub_article','hub_video'], o.type))
		}
				
		let example = { sections: [] };
				
		if(this.props.templates.templates[this.state.template].settings){
			example.sections = this.generateExampleData(this.state.template);
		}
				
		let fonts = _.map(this.props.options.fonts, (font, key) => {
											
			return {
				label: font.name,
				value: font.id
			}
			
		});
		
		let templates_list = [];
		
			
		// TEMPLATES IN GROUPS
		
		templates_list = _.map(templates, (template, key) => {
											
			return {
				label: i18n.t(`campaigns:event_type_${key}`),
				options: _.map(_.orderBy(template, ['sortorder'],['asc']), (item, key2) => {
					return {
						label: item.name,
						value: item.id
					}
				})
			}
		});
				
		/*_.forEach(templates, (template, key) => {
											
			_.forEach(template, (item, key2) => {
				
				templates_list.push({
					label: i18n.t('campaigns:event_type_' + key),
					value: item.id
				});
			});
		});*/
		
		return (
			<div className={this.state.own ? 'container' : null}>
				<div className="row">
					<div className="col-xl-5">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:branding_title_logo')}</h4>
							
							<div className="filepond-container minimal">
								<FilePond 
									maxFiles="1"
									ref={ref => this.logo = ref}
									allowFileEncode={true}
									acceptedFileTypes="image/*"
			                      	labelIdle={i18n.t('filepond_idle')}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
			                      	files={this.state.files.logo}
			                      	server={{
										process: null,
										load: (source, load, error, progress, abort, headers) => {
																						
											fetch(source)
												.then((res) => {
										            return res.blob();
										        }).then((res) => {
											      	load(res);
										        });
										}
									}}
									onupdatefiles={(files) => {												
																			
										let existing = this.state.files;
										let existing_changed = this.state.files_changed;
																							
										if(files.length > 0){

											let fileItems = files.map(fileItem => fileItem.file);
											
											if(files[0].origin !== 3){ // NOT LOADED ON INIT
												existing.logo = fileItems;
												existing_changed.logo = true;
											}
											
											//this.updatePreviewData('logo', files[0].getFileEncodeBase64String());
											
										}else{
											existing.logo = [];
											existing_changed.logo = true;
											
											this.updatePreviewData('logo', false);
										}
										
										this.setState({
											files: existing,
											files_changed: existing_changed
										});
									}}
									onaddfile={(error, file) => {
										this.updatePreviewData('logo', 'data:' + file.fileType + ';base64, ' + file.getFileEncodeBase64String());
									}}
									imageEditEditor={Doka.create({
										onconfirm: (output, item) => {
																			
											setTimeout(() => {
												let file = this.logo.getFiles();
												this.updatePreviewData('logo', 'data:' + file[0].fileType + ';base64, ' + file[0].getFileEncodeBase64String());
											}, 2000);
											
											let existing_changed = this.state.files_changed;
											existing_changed.logo = true;
											
											this.setState({
												files_changed: existing_changed
											});
										}
									})}
								/>
							</div>										
						</div>
						<div className="row">
							
							<div className="col-xl-6">
								<div className="c-card equalize">
									<h4 className="u-mb-medium">{i18n.t('retailers:branding_title_icon')}</h4>
									
									<div className="filepond-container minimal">
										<FilePond 
											maxFiles="1"
											ref={ref => this.icon = ref}
											allowFileEncode={true}
											acceptedFileTypes="image/*"
					                      	labelIdle={i18n.t('filepond_idle')}
					                      	labelFileLoading={i18n.t('filepond_fileLoading')}
					                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
					                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
										    imageCropAspectRatio="1:1"
										    stylePanelAspectRatio="1:1"
										    files={this.state.files.icon}
					                      	server={{
												process: null,
												load: (source, load, error, progress, abort, headers) => {
																								
													fetch(source)
														.then((res) => {
												            return res.blob();
												        }).then((res) => {
													      	load(res);
												        });
												}
											}}
											onupdatefiles={(files) => {
		
												let existing = this.state.files;
												let existing_changed = this.state.files_changed;
												
												if(files.length > 0){

													let fileItems = files.map(fileItem => fileItem.file);
		
													if(files[0].origin !== 3){ // NOT LOADED ON INIT
														existing_changed.icon = true;
														existing.icon = fileItems;
													}
													
													//this.updatePreviewData('icon', files[0].getFileEncodeBase64String());
													
												}else{
													existing_changed.icon = true;
													existing.icon = [];
													
													this.updatePreviewData('icon', false);
												}
												
												this.setState({
													files: existing,
													files_changed: existing_changed
												});
											}}
											onaddfile={(error, file) => {
												this.updatePreviewData('icon', 'data:' + file.fileType + ';base64, ' + file.getFileEncodeBase64String());
											}}
											imageEditEditor={Doka.create({
												onconfirm: (output) => {
													
													setTimeout(() => {
														let file = this.icon.getFiles();
														
														if(file[0] && file[0].fileType){
															this.updatePreviewData('icon', 'data:' + file[0].fileType + ';base64, ' + file[0].getFileEncodeBase64String());
														}
													}, 2000);
													
													let existing_changed = this.state.files_changed;
													existing_changed.icon = true;
													
													this.setState({
														files_changed: existing_changed
													});
												}
											})}
										/>
									</div>
								</div>
							</div>
							<div className="col-xl-6">
								<div className="c-card equalize">
									<h4 className="u-mb-medium">{i18n.t('retailers:branding_title_colors')}</h4>
									
									<Field
										name="color_background"
										label={i18n.t('retailers:branding_label_color_background')}
										type="color"
										color={this.state.color.background}
										onChangeFunc={(color) => { this.onColorChange('background', color); }}
										component={InputField}
									/>
									<Field
										name="color_text"
										label={i18n.t('retailers:branding_label_color_text')}
										type="color"
										color={this.state.color.text}
										onChangeFunc={(color) => { this.onColorChange('text', color); }}
										component={InputField}
									/>
									<Field
										name="color_primary"
										label={i18n.t('retailers:branding_label_color_primary')}
										type="color"
										color={this.state.color.primary}
										onChangeFunc={(color) => { this.onColorChange('primary', color); }}
										component={InputField}
									/>
									<Field
										name="color_secondary"
										label={i18n.t('retailers:branding_label_color_secondary')}
										type="color"
										color={this.state.color.secondary}
										onChangeFunc={(color) => { this.onColorChange('secondary', color); }}
										component={InputField}
									/>
								</div>

							</div>
						</div>
								
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:branding_title_font')}</h4>
							
							<div className="row">
								<div className="col-xl-6">
									<Field
										name="font_text"
										label={i18n.t('retailers:branding_label_font_text')}
										type="suggest"
										options={fonts}
										selected={parseFloat(this.state.font.text)}
										sort={true}
										onChangeFunc={(e) => { this.onFontChange('text', e); }}
										component={InputField}
									/>
								</div>
								<div className="col-xl-6">
									<Field
										name="font_heading"
										label={i18n.t('retailers:branding_label_font_heading')}
										type="suggest"
										options={fonts}
										selected={parseFloat(this.state.font.heading)}
										sort={true}
										onChangeFunc={(e) => { this.onFontChange('heading', e); }}
										component={InputField}
									/>
								</div>
							</div>
						</div>
													
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:branding_title_links')}</h4>
							
							
							<div className="c-tabs tabs"> 
								<button type="button" className={`c-tabs__link ${this.state.links === 'general' ? 'active' : ''}`} onClick={() => { this.toggleLinksType('general'); }}>
									{i18n.t('retailers:branding_links_tab_general')}
								</button>
								<button type="button" className={`c-tabs__link ${this.state.links === 'social' ? 'active' : ''}`} onClick={() => { this.toggleLinksType('social'); }}>
									{i18n.t('retailers:branding_links_tab_social')}
								</button>
								<button type="button" className={`c-tabs__link ${this.state.links === 'footer' ? 'active' : ''}`} onClick={() => { this.toggleLinksType('footer'); }}>
									{i18n.t('retailers:branding_links_tab_footer')}
								</button>
							</div>
							
							
							{this.state.links === 'general' &&
								
								<React.Fragment>
									<Field
										label={i18n.t('retailers:branding_label_url_website')} 
										name="url_website"
										type="text"
										onChangeFunc={this.updatePreviewData.bind(this, 'url_website')}
										component={InputField}
									/>
									<Field
										label={i18n.t('retailers:branding_label_url_privacy')} 
										name="url_privacy"
										type="text"
										onChangeFunc={this.updatePreviewData.bind(this, 'url_privacy')}
										component={InputField}
									/>
								</React.Fragment>
							}
							
							{this.state.links === 'social' &&
							
								<React.Fragment>
									<Field
										label={i18n.t('retailers:branding_label_url_facebook')} 
										name="url_facebook"
										prepend="facebook.com/"
										type="text"
										onChangeFunc={this.updatePreviewData.bind(this, 'url_facebook')}
										component={InputField}
									/>
									
									<Field
										label={i18n.t('retailers:branding_label_url_instragram')} 
										name="url_instagram"
										prepend="instagram.com/"
										type="text"
										onChangeFunc={this.updatePreviewData.bind(this, 'url_instagram')}
										component={InputField}
									/>
									
									<Field
										label={i18n.t('retailers:branding_label_url_twitter')} 
										name="url_twitter"
										prepend="twitter.com/"
										type="text"
										onChangeFunc={this.updatePreviewData.bind(this, 'url_twitter')}
										component={InputField}
									/>
								</React.Fragment>
							}
							
							{this.state.links === 'footer' &&
							
								<React.Fragment>
								
									{this.state.footer.length === 0 && 
										<div className="u-mb-medium">
											{i18n.t('retailers:branding_links_footer_empty')}
										</div>
									}
									
									{_.map(this.state.footer, (item, key) => {
																		
										return (
											<div className="row">
												<div className={`col-xl-4`}>
													<Field
														label={i18n.t('retailers:branding_label_url_footer_label')} 
														name={`footer_label_${key}`}
														value={this.state.footer[key].label}
														type="text"
														onChangeFunc={this.onInputChangeFooter.bind(this, 'label', key)}
														component={InputField}
													/>	
												</div>
												<div className={`col-xl-6`}>
													<Field
														label={i18n.t('retailers:branding_label_url_footer_url')} 
														name={`footer_url_${key}`}
														value={this.state.footer[key].url}
														type="text"
														component={InputField}
														onChangeFunc={this.onInputChangeFooter.bind(this, 'url', key)}
													/>	
												</div>
												<div className="col-xl-2">
													<button
														className="c-btn c-btn--danger c-btn--outline c--btn-fullwidth c--btn--push-label u-p-zero" 
														type="button"
														onClick={() => { this.footerAddRemove('remove', key); }}
													>
														<i className="fal fa-trash"></i> 
													</button>
												</div>
											</div>
										);
									})}
									
									<button
										className="c-btn c-btn--outline" 
										type="button"
										onClick={() => { this.footerAddRemove('add'); }}
									>
										{i18n.t('retailers:branding_action_url_footer_add')}
									</button>
								</React.Fragment>
							}
						</div>
					</div>			
											
					<div className="col-xl-7">
						<div className="c-card preview">
							<h4 className="u-mb-medium">
								{i18n.t('retailers:branding_title_preview')}
								
								<div className="options" style={{ minWidth: '130px' }}>
									<Field
										name="template"
										type="suggest"
										options={templates_list}
										selected={this.state.template}
										onChangeFunc={this.onInputTemplateChange.bind(this)}
										component={InputField}
									/>
								</div>
							</h4>
								
							<hr className="splitter u-mb-small" />
											
							<TemplatePreview 
								template={this.props.templates.templates[this.state.template]}
								data={example}
								branding={this.state.preview}
								view={this.state.view} 
							/>
						</div>
					</div>
					
					
				</div>
			</div>
		);
		
	}

	render() {

		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
						
			return (
				<Loading />
			);
		}

		const { handleSubmit } = this.props;
								
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
						
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								{retailers.retailers[this.state.id].name}
							</h2>
						</header>
					</React.Fragment>
				}
				
				<RetailsDetailsToolbar
					formName={formName}
					section="branding"
					id={this.state.id}
					own={this.state.own}
					{...this.props}
				/>
				
				{this.renderPage()}
			</form>
		);
	}
}


const validate = (values) => {	
	
	const errors = {};

	let i = 0;
	
	while (i < 20) {
		if (!values[`footer_label_${i}`]) {
			errors[`footer_label_${i}`] = 'Please enter a label!';
		}
		
		if (!values[`footer_url_${i}`]) {
			errors[`footer_url_${i}`] = 'Please enter a URL!';
		}
		i++;
	}	
	
	return errors;
}

function mapStateToProps({ account, retailers, templates, brand, options }, ownProps){
	return {
		account,
		retailers,
		templates,
		brand,
		options
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchRetailersList,
		saveRetailer,
		userAccount,
		fetchTemplates,
		fetchFonts
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersBranding)
);
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogin } from '../../actions/users.js';
import Languages from '../../components/chrome/languages';
import ErrorAlert from '../../components/chrome/error';
import InputField from '../../components/forms/field.js';
import Submit from '../../components/forms/submit';
import { Base64 } from 'js-base64';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { showNotification } from '../../actions/notifications';
import { imageResize } from '../../helpers/s3';
import { errorsResetErrors } from '../../actions/errors';

import i18n from '../../helpers/i18n'; 

var formName = 'formUserLogin';

class UserLogin extends Component {

	constructor(props) {
	    super(props);
	    this.props.stopSubmission(formName);
	    
	    this.state = {
			display_errrors: false
		}
		
		this.props.errorsResetErrors();
	}

  	onSubmit(values) {
	  	
	  	this.props.errorsResetErrors();
	  	
	  	this.setState({
		  	display_errrors: true
	  	});
	  		  	
	  	this.props.startSubmission(formName);
	  	
	  	let redirect = '/';
		  	
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(queryString.r){
			redirect = Base64.decode(queryString.r);
		}

		this.props.userLogin(formName, values, (account) => {			
			
			if(account.language && account.language !== i18n.language){
				i18n.changeLanguage(account.language, () => {
					this.forceUpdate();
					
					this.props.showNotification(
						'success',
						i18n.t('users:notification_language_changed_title'),
						i18n.t('users:notification_language_changed_description')
					);
			    });
			}
			
			this.props.history.push(redirect);
		});		
	}
  	
	render(){
		
		const { handleSubmit } = this.props;

		return (
			<div className="o-page o-page--center">
        		{this.props.brand.details &&
					<img className="u-mb-small logo" src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
				}
				<div className="o-page__card">
					<div className="c-card c-card--center">										
						<div className="top">
							<h3>{i18n.t('users:login_title')}</h3>
							<p>{i18n.t('users:login_intro', { title: this.props.brand.details.title })}</p>	
						</div>
						
						<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
							
							<Field
								label={i18n.t('users:login_label_email')} 
								name="email"
								type="email"
								component={InputField}
							/>
							<Field
								label={i18n.t('users:login_label_password')} 
								name="password"
								type="password"
								component={InputField}
							/>
							
							{this.state.display_errrors && 	
								<ErrorAlert />
							}
							
							<Submit 
								label={i18n.t('users:login_action_submit')} 
								form={formName} 
							/>
							
						</form>
						
						<Languages 
							forceUpate={() => { this.forceUpdate.bind(this); }}
						/>
					</div>
					<div className="u-text-center u-mt-small">
						<Link to="/login/forgotten" className="u-text-small">
							{i18n.t('users:login_action_forgotten')}
						</Link>
					</div>
				</div>
				
				<div className="poweredby">
					Powered by <a href="https://www.objective1.co.uk" target="_blank" rel="noopener noreferrer">{this.props.brand.details.title}</a>
				</div>
        	</div>
		);
	}
}

function validate(values) {

	const errors = {};
	var validator = require("email-validator");

	if (!values.email) {
		errors.email = 'Enter your email address!';
	}else if(!validator.validate(values.email)){
		errors.email = 'Enter a valid email address!';
	}

	if(!values.password) {
		errors.password = 'Enter your password!';
	}
	
	return errors;
}


function mapStateToProps({ brand }, ownProps) {
	return { 
		brand
	};
}
export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		userLogin,
		startSubmission, 
		stopSubmission,
		showNotification,
		errorsResetErrors
	} )(UserLogin)
);
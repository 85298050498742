import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import Toolbar from '../../components/chrome/toolbar';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';

import { checkPermission } from '../../helpers/permissions';
import { importSubscribers, fetchSubscribersGroups } from '../../actions/subscribers';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform);


var formName = 'formSubscribersImport';

class SubscribersImport extends Component {

	constructor(props){
		super(props);
		
		this.props.stopSubmission(formName);	
		
		let { id } = this.props.match.params;
		
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
		
		this.props.fetchSubscribersGroups(false, () => {
	       	this.updateDomElements();
       	});
       						       	       			
		this.state = {
			id: id,
			map: false,
			rows: 0,
			header: true
		}
		
		this.updateDomElements();		
	}
	
	onInputMapChange(type, selected){
		
		let map = this.state.map;
		
		map[type] = selected.value;
						
		this.setState({
			map: map
		});
	}
	
	onInputHeaderChange(event){
		this.setState({
			header: event.target.checked
		});
	}
	
	updateDomElements(){
		
		if(this.props.subscribers.groups.local && this.props.subscribers.groups.local[this.state.id]){
			
			/*let groups = [];
			
			if(this.props.subscribers.users && this.state.user && this.props.subscribers.users[this.state.user]){
		
				let user = this.props.subscribers.users[this.state.user];
							
				_.forEach(this.props.subscribers.groups.local, (group, key) => {
						
					if(group.subscribers && group.subscribers.includes(user.id)){
						groups.push(group.id);
					}
				}, this);
				
						
				this.props.initialize({
					forename: user.forename,
					surname: user.surname,
					email: user.email
				});	
			}else{
				groups.push(parseFloat(this.state.id));
			}*/
		}
	}
	
	onSubmit(values) {
			  				
		let files = _.map(this.csv.getFiles(), (file, key) => {
		  	return {
			  	filename: file.filename,
			  	encoded: file.getFileEncodeBase64String()
			}
		});
					
		if(files.length > 0){
			
			this.props.startSubmission(formName);
			let posting = {};
			
			if(this.state.map){
				posting.map = this.state.map;
			}
			
			if(this.state.header){
				posting.ignore_header = true;
			}
			
			posting.file = files[0];
				
		  	this.props.importSubscribers(formName, this.state.id, posting, (data) => {
			  	
			  	if(this.state.map){
				  	this.props.history.push(`/subscribers/audience/${this.state.id}/users`);
			  	}else{
				  	this.setState({ 
					  	map: {
						  	forename: 'ignore',
						  	surname: 'ignore',
						  	email: 0
						},
						sample: data.sample,
					  	rows: data.rows
					})
			  	}
		  	});
		}
	}
	
	renderColAlphabet(number, result){
		
		if(!result){
			result = '';
		}
		
		let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
		let charIndex = number % alphabet.length
	    let quotient = number/alphabet.length
	    
	    if(charIndex-1 == -1){
	        charIndex = alphabet.length
	        quotient--;
	    }
	    
	    result = alphabet.charAt(charIndex-1) + result;
	   
	    if(quotient>=1){
	        return this.renderColAlphabet(parseInt(quotient), result);
	    }else{
	        return result;
	    }
	}
	
	renderMapping(){
		
		if(this.state.map){
			
			let sample = _.map(this.state.sample[0], (cell, key) => {				
				
				return ({
					label: this.renderColAlphabet(key+1) + (cell !== '' ? ': ' + cell : ''),
					value: key							
				});
			});
			
			const emailSample = _.clone(sample);
			
			sample.unshift({
				label: i18n.t('subscribers:import_label_ignore'),
				value: 'ignore'
			});
					
			return (
				<React.Fragment>					
					<div className="row u-mt-medium u-mt-small">
						<div className="col-xl-4 col-md-12">
							<Field
								label={i18n.t('subscribers:import_label_forename')} 
								name="forename"
								type="suggest"
								options={sample}
								selected={this.state.map.forename}
								onChangeFunc={(selected) => { this.onInputMapChange('forename', selected) }}
								component={InputField}
							/>
						</div>
						<div className="col-xl-4 col-md-12">
							<Field
								label={i18n.t('subscribers:import_label_surname')} 
								name="surname"
								type="suggest"
								options={sample}
								selected={this.state.map.surname}
								onChangeFunc={(selected) => { this.onInputMapChange('surname', selected) }}
								component={InputField}
							/>
						</div>
						<div className="col-xl-4 col-md-12">
							<Field
								label={i18n.t('subscribers:import_label_email')} 
								name="email"
								type="suggest"
								options={emailSample}
								selected={this.state.map.email}
								onChangeFunc={(selected) => { this.onInputMapChange('email', selected) }}
								component={InputField}
							/>
						</div>
					</div>
					
					<Field
						labelSwitch={i18n.t('subscribers:import_label_header_row')} 
						name="header"
						type="switch"
						selected={this.state.header}
						onChangeFunc={this.onInputHeaderChange.bind(this)}
						component={InputField}
					/>					
				</React.Fragment>
			);
		}else{
			return;
		}
		
	}
	
	renderSample(){
		
		if(this.state.map){
			
			let data = _.clone(this.state.sample);
			let total = _.clone(this.state.rows);
			
			if(this.state.header){
				data.splice(0, 1);	
				--total;			
			}
			
			return (
				<React.Fragment>
					<p className="u-mb-medium">{i18n.t('subscribers:import_preview_intro', { sample: data.length, total: total })}</p>
					
					<Table 
						columns={[
							{
								key: "forename",
								label: "Forename"
							},
							{
								key: "surname",
								label: "Surname"
							},
							{
								key: "email",
								label: "Email"
							}
						]}
						data={_.map(data, (row, key) => {						
											
							return ({
								id: key,
								forename: this.state.map.forename === 'ignore' ? '' : row[this.state.map.forename],
								surname: this.state.map.surname === 'ignore' ? '' : row[this.state.map.surname],
								email: row[this.state.map.email]							
							});
						})}
					/>					
					
				</React.Fragment>
			);
		}else{
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					description={i18n.t('subscribers:import_preview_pending', { type: this.state.type })}
				/>
			);
		}
	}

	render() {

		let { subscribers } = this.props;

		if(!subscribers.groups.local || !subscribers.groups.local[this.state.id]){
			
			return (
				<Loading />
			);
		}	
		
		let buttons = [];
		
		if(this.state.map){
		
			buttons.push({
				label: i18n.t('subscribers:action_import_submit'),
				submit: formName
			});
		}
		

		const { handleSubmit } = this.props;

		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('subscribers:import_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('subscribers:import_toolbar_settings'),
						url: `${this.props.parentPath}/audience/${this.state.id}/import`,
						selected: true
					}]}
					buttons={buttons}
				/>
					
				<div className="row">
					<div className="col-xl-6 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('subscribers:import_title_file')} </h4>
							
							<div className="filepond-container">
								<FilePond 
									maxFiles="1"
									ref={ref => this.csv = ref}
			                      	allowFileEncode={true}
									acceptedFileTypes="application/csv, text/csv, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			                      	labelIdle={i18n.t('filepond_idle')}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
									onupdatefiles={(files) => {
										this.setState({ map: false })
									}}
									onaddfile={(error, file) => {
										this.setState({ map: false })
										this.onSubmit();
									}}
								/>
							</div>
							
							{this.renderMapping()}
						</div>
					</div>
					
					<div className="col-xl-6 col-md-12">
						<div className="c-card preview">
							<h4>{i18n.t('subscribers:import_title_preview')} </h4>
							{this.renderSample()}
						</div>
					</div>						
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	return errors;
}

function mapStateToProps({ users, account, subscribers }, ownProps){
	return {
		users,
		account,
		subscribers
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		fetchSubscribersGroups,
		importSubscribers
	} )(SubscribersImport)
);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../../helpers/i18n'; 
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import Loading from '../../../components/chrome/loading';
import Swal from 'sweetalert2';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { connectionSettings, connectionDisconnect, connectionGetUrl, checkWebsite } from '../../../actions/connections';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import { showNotification } from '../../../actions/notifications';

var formName = 'formRetailersIntegrations';

class RetailersIntegrations extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;
		let own = false;

		if(!id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
				this.props.history.push('/');
			}
			
			own = true;
			id = this.props.account.group.id;
			this.props.setSectionOptions('settings', i18n.t('users:settings_title'));
		}else{
			
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_INTEGRATIONS')){
				this.props.history.push('/');
			}
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
			
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(!this.props.retailers.retailers[id]){
				
				if(own){
					this.props.history.push('/settings');
				}else{
					this.props.history.push('/retailers');
				}
				
			}
       	});
       	
       	let checkWebsite = false; // DISABLED CHECKING DUE TO UNRELIABLE AS PORT BLOCKS
       	       	       			
		this.state = {
			id: id,
			own: own,
			expanded: {
				social: false
			},
			twitter: false,
			checkWebsite: checkWebsite
		}
		
		if(checkWebsite && (typeof this.props.connections.website === 'undefined' || this.props.connections.website !== 'checking')){
	       	this.props.checkWebsite();
       	}
		
		this.props.connectionSettings(['facebook','instagram']);
		
		this.props.connectionGetUrl('twitter', {}, (response) => {
	       	this.setState({
		       	twitter: response
	       	});
	    });
	    
		this.connectionRefreshTimer = false;
	}
	
	componentDidMount(){
		window.scrollTo(0,0);		
	}
	
	componentWillUnmount(){
		clearInterval(this.connectionRefreshTimer);
	}
	
	startConnectionTime(){
		
		this.connectionRefreshTimer = setInterval(() => {			
			if(localStorage.getItem('connection_refresh')){
				
				switch(localStorage.getItem('connection_refresh')){
					
					case 'twitter':
						this.props.showNotification(
							'success',
							i18n.t('retailers:notification_integration_twitter_connected_title'),
							i18n.t('retailers:notification_integration_twitter_connected_description')
						);
						
						ReactTooltip.rebuild();
						break;
						
					default:
					
						break;
				}
				
				this.props.fetchRetailersList(this.state.id);
				localStorage.removeItem('connection_refresh');
				clearInterval(this.connectionRefreshTimer);
			}
			
		}, 2500);
	}
	
	renderFacebook(){
		
		if(!this.props.connections.settings.facebook){
			return;
		}
		
		let retailer = this.props.retailers.retailers[this.state.id];
		let channels = [];
		let status = i18n.t('retailers:integration_social_facebook_disconnected_status');
		
		if(retailer.details.facebook_pages){
			_.forEach(retailer.details.facebook_pages, (value, key) => {
				channels.push(value.name);
			});
		}
		
		if(retailer.details.facebook_id){
			status = i18n.t('retailers:integration_social_facebook_connected_status', { count: channels.length });
		}
				
		let element = (
			<div className="c-card equalize clickable">
				<h5 className="u-mb-small">
					<i className="fab fa-facebook"></i>
					{i18n.t('retailers:integration_social_facebook_title')}
					<span className={`status c-badge c-badge--${retailer.details.facebook_id ? 'success' : 'danger'}`} data-tip={retailer.details.facebook_id ? channels.join(', ') : ''}>
						{status}
					</span>
				</h5>
				<p>{i18n.t('retailers:integration_social_facebook_description')}</p>
				
				<button className="u-mt-medium c-btn c-btn--info">
					{i18n.t(retailer.details.facebook_id ? 'retailers:integration_social_facebook_connected_cta' : 'retailers:integration_social_facebook_disconnected_cta')}
				</button>
			</div>
		)
		
		if(retailer.details.facebook_id){
			
			return (
				<div 
					className="col-xl-4 col-md-6" 
					onClick={() => { 
												
						Swal.fire({
							title: i18n.t('retailers:integration_facebook_disconnect_dialog_title'),
							text: i18n.t('retailers:integration_facebook_disconnect_dialog_description'),
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: i18n.t('dialog_confirm'),
							cancelButtonText: i18n.t('dialog_cancel'),
							focusConfirm: false
						}).then((result) => {
	
							if (result.value) {
								
								this.props.connectionDisconnect('facebook', () => {
							
									this.props.fetchRetailersList(this.state.id);
									
									this.props.showNotification(
										'success',
										i18n.t('retailers:notification_integration_facebook_disconnected_title'),
										i18n.t('retailers:notification_integration_facebook_disconnected_description')
									);
									
									ReactTooltip.rebuild();
								}); 
							}
						});														
					}}>
					{element}
				</div>
			)
			
		}else{
			return (
				<FacebookLogin
				    appId={this.props.connections.settings.facebook.app_id}
				    scope={this.props.connections.settings.facebook.permissions.join(',')}
				    version={this.props.connections.settings.facebook.version}
				    fields="name,email,picture"
				    callback={(response) => { 
					  	
					  	this.props.saveRetailerIntegration(formName, 'facebook', response, () => {

							this.props.fetchRetailersList(this.state.id);
							
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_facebook_connected_title'),
								i18n.t('retailers:notification_integration_facebook_connected_description')
							);
							
							ReactTooltip.rebuild();
					  	});
				    }}
				    render={renderProps => (
					    <div className="col-xl-4 col-md-6" onClick={renderProps.onClick}>
							{element}
						</div>
					)}
				/>
			)
		}		
	}
	
	renderInstagram(){
		
		if(!this.props.connections.settings.instagram){
			return;
		}
		
		let retailer = this.props.retailers.retailers[this.state.id];
		let channels = [];
		let status = i18n.t('retailers:integration_social_instagram_disconnected_status');
		
		if(retailer.details.instagram_pages){
			_.forEach(retailer.details.instagram_pages, (value, key) => {
				channels.push(value.name);
			});
		}
		
		if(retailer.details.instagram_id){
			status = i18n.t('retailers:integration_social_instagram_connected_status', { count: channels.length });
		}
				
		let element = (
			<div className="c-card equalize clickable">
				<h5 className="u-mb-small">
					<i className="fab fa-instagram"></i>
					{i18n.t('retailers:integration_social_instagram_title')}
					<span className={`status c-badge c-badge--${retailer.details.instagram_id ? 'success' : 'danger'}`} data-tip={retailer.details.instagram_id ? channels.join(', ') : ''}>
						{status}
					</span>
				</h5>
				<p>{i18n.t('retailers:integration_social_instagram_description')}</p>
				
				<button className="u-mt-medium c-btn c-btn--info">
					{i18n.t(retailer.details.instagram_id ? 'retailers:integration_social_instagram_connected_cta' : 'retailers:integration_social_instagram_disconnected_cta')}
				</button>
			</div>
		)
		
		if(retailer.details.instagram_id){
			
			return (
				<div 
					className="col-xl-4 col-md-6" 
					onClick={() => { 
												
						Swal.fire({
							title: i18n.t('retailers:integration_instagram_disconnect_dialog_title'),
							text: i18n.t('retailers:integration_instagram_disconnect_dialog_description'),
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: i18n.t('dialog_confirm'),
							cancelButtonText: i18n.t('dialog_cancel'),
							focusConfirm: false
						}).then((result) => {
	
							if (result.value) {
								
								this.props.connectionDisconnect('instagram', () => {
							
									this.props.fetchRetailersList(this.state.id);
									
									this.props.showNotification(
										'success',
										i18n.t('retailers:notification_integration_instagram_disconnected_title'),
										i18n.t('retailers:notification_integration_instagram_disconnected_description')
									);
									
									ReactTooltip.rebuild();
								}); 
							}
						});														
					}}>
					{element}
				</div>
			)
			
		}else{
			return (
				<FacebookLogin
				    appId={this.props.connections.settings.instagram.app_id}
				    scope={this.props.connections.settings.instagram.permissions.join(',')}
				    version={this.props.connections.settings.instagram.version}
				    fields="name,email,picture"
				    callback={(response) => { 
					  	
					  	this.props.saveRetailerIntegration(formName, 'instagram', response, () => {

							this.props.fetchRetailersList(this.state.id);
							
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_instagram_connected_title'),
								i18n.t('retailers:notification_integration_instagram_connected_description')
							);
							
							ReactTooltip.rebuild();
					  	});
				    }}
				    render={renderProps => (
					    <div className="col-xl-4 col-md-6" onClick={renderProps.onClick}>
							{element}
						</div>
					)}
				/>
			)
		}		
	}
	
	renderPage(){
		
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id] || !this.props.connections.settings){
			return (
				<Loading />
			);
		}
		
		let retailer = retailers.retailers[this.state.id];
		
		let website = false;
		let code_badge = 'danger';
		let code_status = 'nowebsite';
		let code_url = '/settings/branding';
		
		let hub = false;
		let domain_badge = 'danger';
		let domain_status = 'disconnected';
				
		if(retailer.details.url_website){
			
			website = retailer.details.url_website;
			code_url = '/settings/integrations/code';
			
			if(this.props.connections.website && this.props.connections.website === 'checking'){
				code_badge = 'warning';
				code_status = 'checking'
			}else{
				
				if(this.props.connections.website){
					code_badge = 'success';
					code_status = 'connected';
				}else{
					code_badge = 'danger';
					code_status = 'disconnected';			
				}
			}
		}
		
		if(retailer.domains){
			
			hub = retailer.domains.hub;
			
			if(retailer.domains.custom){
			
				if(retailer.domains.custom.connected){
					domain_badge = 'success';
					domain_status = 'connected';
					hub = retailer.domains.custom.domain;
				}else{
					domain_status = 'dns';
				}
			}
		}
		
		return (
			<div className="container">
				<div className="c-card">
					<h4>
						{i18n.t('retailers:integration_setup_title', { name: retailer.name })}
						
						{website && 
							<a href={`http://${website}`} target="_blank" rel="noopener noreferrer" className="options c-btn c-btn--info c-btn--outline u-ml-small">
								{i18n.t('retailers:integration_setup_website_visit')}
							</a>
						}
						
						{hub && 
							<a href={`https://${hub}`} target="_blank" rel="noopener noreferrer" className="options c-btn c-btn--info c-btn--outline u-ml-small">
								{i18n.t('retailers:integration_setup_hub_visit')}
							</a>
						}
					</h4>
					<p className="u-mb-medium">{i18n.t('retailers:integration_setup_description')}</p>
					
					<div className="row u-pb-zerosite"> 
						<Link to={code_url} className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_setup_code_title')}
									
									{this.state.checkWebsite && 
										<span className={`status c-badge c-badge--${code_badge}`}>{i18n.t(`retailers:integration_setup_code_${code_status}_status`)}</span>
									}
								</h5>
								<p>{i18n.t('retailers:integration_setup_code_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t(`retailers:integration_setup_code_${code_status}_cta`)}
								</button>
							</div>
						</Link>
						
						<Link to="/settings/integrations/domain" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_setup_domain_title')}
									<span className={`status c-badge c-badge--${domain_badge}`}>{i18n.t(`retailers:integration_setup_domain_${domain_status}_status`)}</span>
								</h5>
								<p>{i18n.t('retailers:integration_setup_domain_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t(`retailers:integration_setup_domain_${domain_status}_cta`)}
								</button>
							</div>
						</Link>
						
						<div className="col-xl-4 col-md-6">
							<div className="c-card equalize disabled">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_api_title')}
									<span className="status c-badge c-badge--warning">
										{i18n.t(retailer.details.instagram_token ? 'retailers:integration_api_connected_status' : 'retailers:integration_social_instagram_soon_status')}
									</span>
								</h5>
								<p>{i18n.t('retailers:integration_api_description')}</p>
								
								<div className="u-mt-medium c-btn c-btn--info">
									{i18n.t('retailers:integration_api_disabled_cta')}
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="c-card">
					<h4>
						{i18n.t('retailers:integration_website_title')}
						
						{website && 
							<a href={`http://${website}`} target="_blank" rel="noopener noreferrer" className="options c-btn c-btn--info c-btn--outline u-ml-small">
								{i18n.t('retailers:integration_setup_website_visit')}
							</a>
						}
					</h4>
					<p className="u-mb-medium">{i18n.t('retailers:integration_website_description')}</p>
					
					<div className="row u-pb-zerosite"> 
						<Link to="/settings/integrations/slider" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_website_slider_title')}
								</h5>
								<p>{i18n.t('retailers:integration_website_slider_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t('retailers:integration_website_slider_cta')}
								</button>
							</div>
						</Link>
						<Link to="/settings/integrations/advert" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_website_advert_title')}
								</h5>
								<p>{i18n.t('retailers:integration_website_advert_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t('retailers:integration_website_advert_cta')}
								</button>
							</div>
						</Link>
						<Link to="/settings/integrations/conversion" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									{i18n.t('retailers:integration_website_conversion_title')}
								</h5>
								<p>{i18n.t('retailers:integration_website_conversion_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t('retailers:integration_website_conversion_cta')}
								</button>
							</div>
						</Link>
					</div>
				</div>
				
				<div className="c-card">
					<h4>
						{i18n.t('retailers:integration_email_title')}
						
						<Link to="/subscribers" className="options c-btn c-btn--info c-btn--outline u-ml-small">
							{i18n.t('retailers:integration_email_action_subscribers')}
						</Link>
					</h4>
					<p className="u-mb-medium">{i18n.t('retailers:integration_email_description')}</p>
					
					<div className="row u-pb-zeroemail"> 
						<Link to="/settings/integrations/email" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									<i className="fal fa-at"></i>
									{i18n.t('retailers:integration_email_local_title')}
								</h5>
								<p>{i18n.t('retailers:integration_email_local_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t('retailers:integration_email_local_cta')}
								</button>
							</div>
						</Link>
						<Link to="/settings/integrations/mailchimp" className="col-xl-4 col-md-6">
							<div className="c-card equalize">
								<h5 className="u-mb-small">
									<i className="fab fa-mailchimp"></i>
									{i18n.t('retailers:integration_email_mailchimp_title')}
									<span className={`status c-badge c-badge--${retailer.details.mailchimp_id ? 'success' : 'danger'}`}>{`${retailer.details.mailchimp_id ? i18n.t('retailers:integration_email_mailchimp_connected_status') : i18n.t('retailers:integration_email_mailchimp_disconnected_status')}`}</span>
								</h5>
								<p>{i18n.t('retailers:integration_email_mailchimp_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t(retailer.details.mailchimp_id ? 'retailers:integration_email_mailchimp_connected_cta' : 'retailers:integration_email_mailchimp_disconnected_cta')}
								</button>
							</div>
						</Link>
					</div>
				</div>
				
				<div className="c-card">
					<h4>
						{i18n.t('retailers:integration_social_title')}
					</h4>
					<p className="u-mb-medium">
						{i18n.t('retailers:integration_social_description')}
					</p>
					
					<div className="row u-pb-zerosocial"> 
						{this.renderFacebook()}
						{this.renderInstagram()}
					    
						<div
							className="col-xl-4 col-md-6"
							onClick={() => { 
								
								if(retailer.details.twitter_id){
									
									Swal.fire({
										title: i18n.t('retailers:integration_twitter_disconnect_dialog_title'),
										text: i18n.t('retailers:integration_twitter_disconnect_dialog_description'),
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: i18n.t('dialog_confirm'),
										cancelButtonText: i18n.t('dialog_cancel'),
										focusConfirm: false
									}).then((result) => {
				
										if (result.value) {
											
											this.props.connectionDisconnect('twitter', () => {
										
												this.props.fetchRetailersList(this.state.id);
												
												this.props.showNotification(
													'success',
													i18n.t('retailers:notification_integration_twitter_disconnected_title'),
													i18n.t('retailers:notification_integration_twitter_disconnected_description')
												);
												
												ReactTooltip.rebuild();
											}); 
										}
									});	
									
								}else if(this.state.twitter){
									this.startConnectionTime();
									//this.props.connectionGetUrl('twitter', {}, (response) => {
									window.open(this.state.twitter,'twitter','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=400');
									//});									
								}else{
									window.location = '/settings/integrations';
								}
							}}
						>
							<div className="c-card equalize clickable">
								<h5 className="u-mb-small">
									<i className="fab fa-twitter"></i>
									{i18n.t('retailers:integration_social_twitter_title')}
									<span className={`status c-badge c-badge--${retailer.details.twitter_id ? 'success' : 'danger'}`} data-tip={retailer.details.twitter_id && retailer.details.twitter_channels ? retailer.details.twitter_channels[0].name : ''}>
										{i18n.t(retailer.details.twitter_id ? 'retailers:integration_social_twitter_connected_status' : 'retailers:integration_social_twitter_disconnected_status')}
									</span>
								</h5>
								<p>{i18n.t('retailers:integration_social_twitter_description')}</p>
								
								<button className="u-mt-medium c-btn c-btn--info">
									{i18n.t(retailer.details.twitter_id ? 'retailers:integration_social_twitter_connected_cta' : 'retailers:integration_social_twitter_disconnected_cta')}
								</button>
							</div>
						</div>
					</div>
				</div>
				<ReactTooltip 
					effect="solid"
				/>
			</div>
		)
	}
	
	render() {
				
		return (
			<React.Fragment>
				
				<RetailsDetailsToolbar
					formName={formName}
					section="integrations"
					id={this.state.id}
					own={this.state.own}
					{...this.props}
				/>
				
				{this.renderPage()}
			</React.Fragment>
		);
	}
}


function mapStateToProps({ account, retailers, connections }, ownProps){
	return {
		account,
		retailers,
		connections
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRetailersList,
	connectionSettings,
	connectionDisconnect,
	connectionGetUrl,
	saveRetailerIntegration,
	showNotification,
	checkWebsite
})(RetailersIntegrations);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectionSettings } from '../../../actions/connections';

class IntegrationsDomain extends Component {
	
	constructor(props){
		super(props);
       	this.props.connectionSettings(['dns']);
    }
    
    render() {		
		
		let domain = false;
		
		if(this.props.retailer.domains && this.props.retailer.domains.custom){
			domain = this.props.retailer.domains.custom.domain;
		}	
		
		if(!domain){
			
			return (
				<React.Fragment>	
					No custom domain name set!
				</React.Fragment>	
			);
		
		}else{
		
			let ip = '';
			
			if(this.props.connections.settings && this.props.connections.settings.dns && this.props.connections.settings.dns.ip){
				ip = this.props.connections.settings.dns.ip;
			}
						
			return (
				<React.Fragment>						
					<ol className="numbererd">
						<li>Log in to the account of the domain provider and find the DNS settings for {domain}. Look for your domain management area, DNS configuration, or similar.</li>
						<li>Edit your A record to point to our IP address {ip}. The domain settings panel might feature drop-downs or editable fields, or you might have to check boxes or edit a table. You need to:
							<ol className="numbererd">
								<li>enter or choose the @ symbol, or A record</li>
								<li>enter our IP address {ip} as the destination for the A record.</li>
							</ol>
						</li>
						<li>Save the A Record (click Save, Save Zone File, Add Record, or similar).</li>
					</ol>
				</React.Fragment>
			);
		}
	}
}

function mapStateToProps({ connections }, ownProps){
	return {
		connections
	};
}

export default connect(mapStateToProps, { 
	connectionSettings
})(IntegrationsDomain);
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Loading from '../../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../../actions/forms';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';
import Soon from '../../../components/chrome/soon';
import SendDeveloper from '../../../components/retailers/send_developer';

var formName = 'formRetailersIntegrationsApi';


class RetailersIntegrationsApi extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	       	
       	this.state = {
	       	id: id,
	       	active: false
       	}
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];
										
			if(retailer.details.api_active){
				
				this.setState({
					active: true
				})
			}
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onInputActiveChange(event){
		this.setState({
			active: event.target.checked
		});
	}
	
	onSubmit(values) {
		
	  	/*this.props.startSubmission(formName);
		  	
	  	this.props.saveRetailerIntegration(formName, 'api', { domain: values.domain }, () => {
			
			this.props.showNotification(
				'success',
				i18n.t('retailers:notification_integration_api_updated_title'),
				i18n.t('retailers:notification_integration_api_updated_description')
			);
				
			this.props.history.push(this.props.parentPath);
	  	});*/
	}

	render() {
			
		if(!this.props.retailers.retailers || !this.props.retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
		
		//const { handleSubmit } = this.props;
		
		//let retailer = this.props.retailers.retailers[this.state.id];
		
		return (
			<div>
				<OverlaySettings closeURL={this.props.parentPath} />
					
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_api_title')}
					</h2>
				</header>
				<Soon
					icon="fa-hourglass-half"
					title={i18n.t('translation:soon_title')}
					description={i18n.t('translation:soon_description')}
				/>	
			</div>
		);
		
			/*return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_api_title')}
					</h2>
				</header>
				
				<div className="row cta-push">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_api_settings_title')}</h4>
										
							<Field
								labelSwitch={i18n.t('retailers:integration_api_label_active')} 
								name="active"
								type="switch"
								selected={this.state.active}
								onChangeFunc={this.onInputActiveChange.bind(this)}
								component={InputField}
							/>
						</div>
						
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4>{i18n.t('retailers:integration_api_instructions_title')}</h4>
							<p className="u-mb-medium">You can integrate to our API using the following API key:</p>
							
							<CopyCode
								className="u-mt-small u-mb-small"
								value="KEY HERE"
								onCopy={() => {
									this.props.showNotification(
										'success',
										i18n.t('retailers:notification_integration_api_key_copied_title'),
										i18n.t('retailers:notification_integration_api_key_copied_description')
									);	
								}}
							/>
							
							<br/>
							<SendDeveloper />
						</div>
						
					</div>					
						
				</div>
								
				<div id="cta">
					<Submit 
						label={i18n.t('retailers:integration_api_action_save')} 
						form={formName} 
					/>
				</div>
			</form>
		);*/
	}
}

const validate = (values) => {	
	
	const errors = {};

	return errors;
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		saveRetailerIntegration,
		showNotification,
		fetchRetailersList
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsApi)
);

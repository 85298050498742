import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import SubscribersUserToolbar from './toolbar_user';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';

import { checkPermission } from '../../helpers/permissions';
import { fetchSubscribersHistory } from '../../actions/subscribers';
import { fetchCampaignsList } from '../../actions/campaigns';

class SubscribersHistory extends Component {

	constructor(props){
		super(props);
				
		let { id, user } = this.props.match.params;
		
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
		
		this.props.fetchCampaignsList();
		this.props.fetchSubscribersHistory(user);
       						       	       			
		this.state = {
			id: id,
			user: user
		}		
	}
	
	renderPage(){
		
		let { subscribers, campaigns } = this.props;
		
		if(!subscribers.history || !subscribers.history[this.state.user] || !campaigns.campaigns){
			
			return (
				<Loading />
			);
		}		
		
		let history = subscribers.history[this.state.user];
		
		if(_.isEmpty(history)){

			return (
				<React.Fragment>
				
					<OverlaySettings closeURL={this.props.parentPath} />
					<header className="c-navbar u-mb-medium">
						<h2 className="c-navbar__title">
							{i18n.t('subscribers:user_title_history')}
						</h2>
					</header>
					
					<SubscribersUserToolbar
						section="history"
						id={this.state.id}
						user={this.state.user}
						{...this.props}
					/>		
									
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('subscribers:empty_user_history_title')}
						description={i18n.t('subscribers:empty_user_history_description')}
					/>
				</React.Fragment>
			);
		}
		
		return (
			<div className="c-card">
					
				<Table 
					columns={[
						{
							key: "date",
							label: "Date",
							sortable: true,
							format: 'datetime'
						},
						{
							key: "campaign",
							label: "Campaign",
							sortable: true
						}
					]}
					data={_.map(history, (item, key) => {
						
						let campaign = 'Unknown';
						
						if(campaigns.campaigns[item.campaign_id]){
							campaign = campaigns.campaigns[item.campaign_id].internal_name;
						}
																				
						return ({
							date: item.datetime.date,
							campaign: campaign,
							campaign_id: item.campaign_id,
							event_id: item.event_id,
						});
					})}
					sort={{
						column: 'date',
						order: 'asc'
					}}
					rowClick={(row) => {
						this.props.history.push(`/campaigns/${row.campaign_id}/schedule/event/${row.campaign_id}/${row.event_id}`);
					}}						
				/>
			</div>
		)
		
	}
	
	render() {
							
		return (

			<React.Fragment>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('subscribers:user_title_history')}
					</h2>
				</header>
				
				<SubscribersUserToolbar
					section="history"
					id={this.state.id}
					user={this.state.user}
					{...this.props}
				/>		

				{this.renderPage()}
			</React.Fragment>
		);
	}
}


function mapStateToProps({ users, account, subscribers, campaigns }, ownProps){
	return {
		users,
		account,
		subscribers,
		campaigns
	};
}

export default connect(mapStateToProps, { 
	fetchSubscribersHistory,
	fetchCampaignsList
})(SubscribersHistory);
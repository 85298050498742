// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import _ from 'lodash';
import OverlaySettings from '../../components/chrome/overlay_settings';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { predictCountry } from '../../actions/users';
import { imageUrl } from '../../helpers/s3';

import { setSectionOptions } from '../../actions/chrome';
import RegionalDetailsToolbar from './toolbar';
import { checkPermission } from '../../helpers/permissions';
import { fetchRegionalList, saveRegional } from '../../actions/regional';
import { fetchTimezones } from '../../actions/options';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var countries = require("i18n-iso-countries");
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

var formName = 'formRegionalEdit';

class RegionalEdit extends Component {

	constructor(props){
		super(props);
		
		let language = i18n.language.split('-');
		countries.registerLocale(require(`i18n-iso-countries/langs/${language[0]}.json`));
				
		let { id } = this.props.match.params;
		
		if(!id){
			this.props.history.push('/regional');
		}
						
		if(id === 'add'){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'REGIONAL_ADD')){
				this.props.history.push('/regional');
			}
			
			id = false;
			
			this.props.fetchTimezones();
			this.props.predictCountry(() => {
				this.updateDomElements();
			});
							
		}else{
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'REGIONAL_EDIT')){
				this.props.history.push('/');
			}
			
			this.props.fetchRegionalList(id, () => {
	       	
		       	if(this.props.regional.groups && !this.props.regional.groups[id]){
					this.props.history.push('/regional');
				}else{
					this.updateDomElements();
				}
	       	});		       	
		}
			
		
		this.state = {
			id: id,
			country: false,
			language: language,
			user_add: true,
			user_language: this.props.brand.details.languages.length == 1 ? this.props.brand.details.languages[0] : false,
			files: [],
			files_changed: false,
		}
	}	
	
	updateDomElements(){
		
		if(this.props.regional && this.props.regional.groups && this.state.id && this.props.regional.groups[this.state.id]){
		
			let regional = this.props.regional.groups[this.state.id];	
			let groups = [];
			
			let files = [];
						
			if(regional.image){
				
				files = [
					{
						source: imageUrl(regional.image),
						options: {
			              	type: 'local'
			            }
					}
				];
			}
	
			this.setState({
				country: regional.details.country ? regional.details.country : '',
				files: files,
				files_changed: false
			});

			this.props.initialize({
				name: regional.name,
				...regional.details
			});	
		}
		
		if(!this.state.id && !this.state.country && this.props.users.country_prediction){
			this.onInputCountryChange(this.props.users.country_prediction);
			this.props.change('country', this.props.users.country_prediction);	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements();
	}
	
	onSubmit(values) {

	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);

	  	posting.country = this.state.country;
	  	
	  	if(this.state.files_changed){
			
			let files = _.map(this.avatar.getFiles(), (file, key) => {
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
						
			if(files.length > 0){
				posting.image = files[0].encoded;
			}else {
				posting.image = 'none';
			}
		}
	  	
	  	if(this.state.user_add){
		  	
		  	posting.user = {
			  	forename: values.user_forename,
			  	surname: values.user_surname,
			  	email: values.user_email,
			  	language: this.state.user_language
		  	}
	  	}
	  	
	  	this.props.saveRegional(formName, this.state.id, posting, (id) => {
			this.props.history.push(`${this.props.parentPath}/regional/${id}`);	
		});
	}

	onInputCountryChange(selected){
			
		let country = selected;
		
		if(selected.value){
			country = selected.value;
		}
				
		this.setState({
			country: country
		});
				
		if(this.state.user_add && !this.state.user_language){
		
			let timezone = _.find(this.props.options.timezones, {country_code: country});;
			
			if(timezone){
				
				if(this.props.brand.details.languages.includes(timezone.languages[0])){
					this.setState({
						user_language: timezone.languages[0]
					})
				}
			}
		}
	}
	
	onInputUserLanguageChange(selected){
				
		this.setState({
			user_language: selected.value
		});
	}

	onInputAddChange(event){
		
		this.setState({
			user_add: event.checked
		});
	}

	renderPage(){
		
		let { regional } = this.props;

		if(!regional.groups){
			return (
				<Loading />
			);
		}
		
		return (
			<div className={this.state.own ? 'container' : null}>
				<div className="row">
					<div className="col-xl-4 col-md-12">	
						<div className="c-card">
							
							<h4 className="u-mb-medium">{i18n.t('regional:settings_title_general')} </h4>

							<Field
								label={i18n.t('regional:settings_label_name')} 
								name="name"
								type="text"
								component={InputField}
							/>
															
							<Field
								label={i18n.t('regional:settings_label_country')} 
								name="country"
								type="suggest"
								options={_.map(countries.getNames(this.state.language[0]), (country, key) => {
									
									return {
										label: country,
										value: key
									}
								})}
								selected={this.state.country}
								onChangeFunc={this.onInputCountryChange.bind(this)}
								component={InputField}
							/>
						</div>
						
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('regional:settings_title_avatar')} </h4>
							<div className="filepond-container minimal">
								<FilePond 
									maxFiles="1"
									ref={ref => this.avatar = ref}
			                      	allowFileEncode={true}
									acceptedFileTypes="image/*"
			                      	labelIdle={i18n.t('filepond_idle')}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
			                      	files={this.state.files}
								    imageCropAspectRatio="1:1"
								    stylePanelLayout="circle"
								    stylePanelAspectRatio="1:1"
								    styleButtonRemoveItemPosition="center bottom"
								    styleButtonProcessItemPosition="center bottom"
								    styleLoadIndicatorPosition="center bottom"
								    styleProgressIndicatorPosition="center bottom"
								    className="circle"
								    server={{
										process: null,
										load: (source, load, error, progress, abort, headers) => {
																						
											fetch(source)
												.then((res) => {
										            return res.blob();
										        }).then((res) => {
											      	load(res);
										        });
										}
									}}
									onupdatefiles={(files) => {

										if(files.length > 0){

											let fileItems = files.map(fileItem => fileItem.file);

											if(files[0].origin !== 3){ // NOT LOADED ON INIT
												this.setState({
													files: fileItems,
													files_changed: true
												});
											}
											
										}else{
											this.setState({
												files: [],
												files_changed: true
											});
										}
									}}
									imageEditEditor={Doka.create({
										onconfirm: (output) => {
											this.setState({
												files_changed: true
											});
										}
									})}
								/>
							</div>
						</div>
					</div>
				
					<div className="col-xl-8 col-md-12">
					
						<div className="c-card">
							<h4>{i18n.t('regional:settings_label_notes')} </h4>
							
							<div className="row u-mt-medium">
								<div className="col-12">
									<Field
										name="notes"
										type="textarea-auto"
										component={InputField}
									/>
								</div>
							</div>
						</div>
							
						{!this.state.id && 
							
							<div className="c-card">
							
								<h4>
									{i18n.t('regional:settings_user_title')} 
									
									<div className="options" style={{ marginRight: "-10px" }}>
										<Field
											name="add_user"
											type="switch"
											selected={this.state.user_add}
											onChangeFunc={(event) => { this.onInputAddChange(event.target); } }
											component={InputField}
										/>
									</div>
								</h4>
								
								<p>{i18n.t('regional:settings_user_intro')}</p>
								
								{this.state.user_add && 
									
									<React.Fragment>
										<div className="row u-mt-medium">
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('regional:user_label_forename')} 
													name="user_forename"
													type="text"
													component={InputField}
												/>
											</div>
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('regional:user_label_surname')} 
													name="user_surname"
													type="text"
													component={InputField}
												/>
											</div>
										</div>
																		
										<div className="row">
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('regional:user_label_email')} 
													name="user_email"
													type="email"
													component={InputField}
												/>
											</div>
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('users:account_label_language')} 
													name="user_language"
													type="suggest"
													options={_.map(this.props.brand.details.languages, (language, key) => {
													
														return {
															label: i18n.t(`translation:language_${language}`),
															value: language,
															//imageDropdown: `/images/flags/${language}.svg`
														}
						
													})}
													sort="asc"
													selected={this.state.user_language}
													onChangeFunc={this.onInputUserLanguageChange.bind(this)}
													component={InputField}
												/>
											</div>									
										</div>
									</React.Fragment>
								}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}

	render() {
			
		const { handleSubmit } = this.props;
		
		let { regional } = this.props;

		if(!regional.groups){
			return (
				<Loading />
			);
		}
		
		let title = i18n.t('regional:title_add_regional');
		
		if(this.state.id && regional.groups[this.state.id]){
			title = regional.groups[this.state.id].name;
		}
		
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
					
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								{title}
							</h2>
						</header>
					</React.Fragment>
				}
				
				<RegionalDetailsToolbar
					formName={formName}
					section="settings"
					id={this.state.id}
					{...this.props}
				/>
				
				{this.renderPage()}
			</form>
		);
	}
}


const validate = (values) => {	
	
	const errors = {};

	if (!values.name) {
		errors.name = 'Enter regional group name!';
	}
	
	if (!values.country) {
		errors.country = 'Choose regional group country!';
	}
	
	if (!values.user_forename) {
		errors.user_forename = 'Enter the users forename!';
	}
	
	if (!values.user_surname) {
		errors.user_surname = 'Enter the users surname!';
	}
	
	if (!values.user_email) {
		errors.user_email = 'Enter the users email!';
	}
	
	if (!values.user_language) {
		errors.user_language = 'Enter the users language!';
	}

	return errors;
}

function mapStateToProps({ account, regional, options, brand, users }, ownProps){
	return {
		account,
		regional,
		options,
		brand,
		users
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchRegionalList,
		saveRegional,
		fetchTimezones,
		predictCountry
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RegionalEdit)
);
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import Loading from '../../../components/chrome/loading';
import _ from 'lodash';
import Toolbar from '../../../components/chrome/toolbar';
import IntegrationsDomain from '../../../components/retailers/integrations/domain';

import Submit from '../../../components/forms/submit';
import { startSubmission, stopSubmission } from '../../../actions/forms';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';
import { checkDns } from '../../../actions/connections';
import SendDeveloper from '../../../components/retailers/send_developer';

const isValidDomain = require('is-valid-domain')
var formName = 'formRetailersIntegrationsDomain';


class RetailersIntegrationsDomain extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	       	
       	this.state = {
	       	id: id,
	       	domain: '',
	       	valid: true,
	       	validating: false
       	}
       	
       	this.checkDomainBouncer = _.debounce((data) => {
	       		
		   	this.setState({
			   	domain: data.target.value
		   	})
				
			this.checkDns();		
			
		}, 250);
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];
		
			if(retailer.domains && retailer.domains.custom){
				this.props.initialize({
					domain: retailer.domains.custom.domain
				});
				
				this.setState({
					domain: retailer.domains.custom.domain,
					valid: retailer.domains.custom.connected
				})
			}	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	checkDns(){
		
		if(isValidDomain(this.state.domain)){
						
			this.props.checkDns(this.state.domain, (connected) => {
				
				this.setState({
					valid: connected,
					validating: false
				})
			});
		}
	}
	
	toggleInstructions(type){
		
		this.setState({
			type: type
		});
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
		  	
	  	this.props.saveRetailerIntegration(formName, 'hub_domain', { domain: values.domain }, () => {
			
			this.props.showNotification(
				'success',
				i18n.t('retailers:notification_integration_domain_updated_title'),
				i18n.t('retailers:notification_integration_domain_updated_description')
			);
				
			this.props.history.push(this.props.parentPath);
	  	});
	}

	render() {
			
		if(!this.props.retailers.retailers || !this.props.retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const { handleSubmit } = this.props;
		const retailer = this.props.retailers.retailers[this.state.id];
		
		let connected = '';
		
		if(isValidDomain(this.state.domain)){
			
			connected = (
				<span className="status c-badge c-badge--success">{i18n.t(`retailers:integration_setup_domain_connected_status`)}</span>
			);
			
			if(this.state.validating){
				
				connected = (
					<span className="status c-badge c-badge--warning">{i18n.t(`retailers:integration_setup_domain_checking_status`)}</span>
				);
				
			}else if(!this.state.valid){
				connected = (
					<div className="c-card c-alertc-alert--danger u-mb-none">
		                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
							<i className="fal fa-unlink" style={{ color: '#FFF' }}></i>
						</span>
					
						<div className="c-alert__content">
							<h4 className="c-alert__title">{i18n.t('retailers:integration_domain_settings_disconnected_title')}</h4>
							<p className="u-mb-small">{i18n.t('retailers:integration_domain_settings_disconnected_description')}</p>
							
							<button type="button" className="c-btn c-btn--info" onClick={() => {
								
								this.setState({
									validating: true
								});
								
								this.checkDns()
							}}>
								{i18n.t('retailers:integration_domain_settings_label_domain_check')}
							</button>
						</div>
					</div>
				);
			}
		}
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_domain_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:integration_domain_toolbar_settings'),
						url: `${this.props.parentPath}/integrations/domain`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('retailers:integration_domain_action_save'),
						submit: formName
					}]}
				/>
				
				<div className="row">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4>{i18n.t('retailers:integration_domain_settings_title')}</h4>
							<p className="u-mb-medium">{i18n.t('retailers:integration_domain_settings_intro', { default_domain: retailer.domains ? retailer.domains.hub : 'unknown' })}</p>
										
							<Field
								label={i18n.t('retailers:integration_domain_settings_label_domain')}
								name="domain"
								type="text"
								onChange={this.checkDomainBouncer}
								component={InputField}
							/>
							
							{connected}
						</div>
						
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_domain_instructions_title')}</h4>
							
							<IntegrationsDomain
								retailer={retailer}
							/>
						
							<br/>
							<SendDeveloper />
						</div>
						
					</div>					
						
				</div>
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if(values.domain){
		
		let checkDomain = isValidDomain(values.domain);
		
		if(!checkDomain){
			errors.domain = 'Please enter a valid domain!';
		}
	}

	
	return errors;
}

function mapStateToProps({ account, retailers, connections }, ownProps){
	return {
		account,
		retailers,
		connections
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		saveRetailerIntegration,
		showNotification,
		fetchRetailersList,
		checkDns
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsDomain)
);

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import { tagsArray } from '../../helpers/tags';
import CollectionsToolbar from './toolbar';
import Loading from '../../components/chrome/loading';

import { saveCollection } from '../../actions/assets';
import { fetchTags } from '../../actions/options';
import { fetchCollectionsList } from '../../actions/assets';

import moment from 'moment-timezone';
import 'moment/min/locales';

import { checkPermission } from '../../helpers/permissions';

var formName = 'formCollectionsEdit';

class CollectionsEdit extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;
		
        if(id){
	        
	       	// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'COLLECTIONS_EDIT')){
				this.props.history.push('/');
			}
			
			this.props.fetchCollectionsList(id, () => {
		       	
		       	if(this.props.assets.collections && !this.props.assets.collections[id]){
					this.props.history.push('/assets');
				}else{
					this.updateDomElements();
				}
	       	});
	       	
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'COLLECTIONS_ADD')){
				this.props.history.push('/');
			}
		}
					
		moment.locale(i18n.language);
		
		this.props.stopSubmission(formName);
		
		this.state = {
			id: id,
			embargo: false,
			embargoDate: new Date(),
			selected: []
		}
		
		this.props.fetchTags(() => {
			this.updateDomElements();
		});
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements(); 
	}
	
	updateDomElements(){
		
		if(this.props.assets.collections && this.props.options.tags && this.state.id){
		
			let collection = this.props.assets.collections[this.state.id];			
			let selected = [];
			
			if(collection.readonly === 1){
				this.props.history.push(`/assets/collection/${this.state.id}`);
			}
			
			if(collection.tags){
																
				_.forEach(collection.tags, (tag, key) => {	
										
					if(this.props.options.tags.asset && this.props.options.tags.asset[key]){
						
						tag = this.props.options.tags.asset[key];
						
						selected.push({
							label: i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`),
							value: tag.id,
							fixed: true
						});
					
					}else{
						selected.push({
							label: tag,
							value: key
						});
					}
				});
			}
						
			let embargo = false;
			let embargoDate = this.state.embargoDate;
			
			if(collection.embargo && collection.embargo !== null){
				embargo = true;
				embargoDate = moment.utc(collection.embargo.date).tz(this.props.account.profile.timezone).toDate();
			}
						
			this.setState({
				selected: selected,
				embargo: embargo,
				embargoDate: embargoDate
			});	

			this.props.initialize(collection);
		}
	}
	

	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  	
	  	// CLEAN EMBARGO DATE
	  	if(posting.embargo){
		  	posting.embargo = moment(this.state.embargoDate).format('YYYY-MM-DD hh:mm');
	  	}else{
		  	delete(posting.embargo);
	  	}
	  	
	  	delete(posting.embargo_date);
	  	
	  	// TAGS
	  	if(this.state.selected.length > 0){
		  	posting.tags = tagsArray(this.state.selected);
	  	}
	  	
	  	this.props.saveCollection(formName, this.state.id, posting, (id) => {
		  	
		  	if(this.state.id){
			  	this.props.history.push(`${this.props.parentPath}/collection/${id}/edit`);
		  	}else{
			  	this.props.history.push(`${this.props.parentPath}/collection/${id}`);
		  	}
					
		});
	}
	
	onInputChangeTag(selected) {
	    this.setState({ selected: selected });
	}
	
	onInputDateChange(date){

		this.setState({
			embargoDate: date
		});
	}
	
	render() {
		
		if(this.state.id && (!this.props.assets.collections || !this.props.assets.collections[this.state.id])){
			return (
				<Loading />
			);
		}

		const { handleSubmit } = this.props;
		let tags = [];
				
		if(this.props.options.tags && this.props.options.tags.collection){
			
			tags = _.map(this.props.options.tags.collection, (tag, key) => {
		  	
			  	return {
					label: i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`),
					value: tag.id,
					fixed: true
				}
			});			
		}
		
		let title = i18n.t('assets:collections_title_add');
		let readonly = false;
		
		if(this.state.id){
			let collection = this.props.assets.collections[this.state.id];
			
			title = collection.name;
			
			if(collection.tags){
								
				_.forEach(collection.tags, (tag, key) => {					
			
					if(!_.find(tags, { id: key })) {
						
						tags.push({
							label: tag,
							value: key
						});
					}
				});
			}
			
			if(collection.readonly === 1){
				readonly = true;
			}
		}
				
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<CollectionsToolbar
					section="settings"
					readonly={readonly}
					formName={formName}
					id={this.state.id}
					{...this.props}
				/>
					
				<div className="c-card">
					<Field
						label={i18n.t('assets:collections_label_name')} 
						name="name"
						type="text"
						component={InputField}
					/>
					
					<Field
						label={i18n.t('assets:collections_label_tags')} 
						name="tags"
						type="suggest"
						options={tags}
						component={InputField}
						multiple={true}
						custom={true}
						selected={this.state.selected}
						onChangeFunc={this.onInputChangeTag.bind(this)}
					/>
					
					<Field
						name="embargo"
						type="switch"
						labelSwitch={i18n.t('assets:collections_label_embargo')} 
						selected={this.state.embargo}
						onChangeFunc={(event) => {

							if(event.target.checked){
								this.setState({
									embargo: true
								});
								
								this.props.change('embargo_date', this.state.embargoDate);
								
							}else{
								this.setState({
									embargo: false
								});
							}							
						}}
						component={InputField}
					/>
									
					{this.state.embargo && 
						
						<div>
							<Field
								name="embargo_date"
								type="datepicker"
								selected={this.state.embargoDate}
								onChangeFunc={this.onInputDateChange.bind(this)}
								timepicker={true}
								component={InputField}
							/>
						</div>
					}
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.name) {
		errors.name = 'Enter a collection name!';
	}

	if(values.embargo) {
		
		if (!values.embargo_date) {
			errors.embargo_date = 'Enter an embargo date!';
		}
		
		if (!values.embargo_time) {
			errors.embargo_time = 'Enter an embargo time!';
		}
	}
	
	return errors;
}

function mapStateToProps({ assets, account, options, brand }, ownProps){
	return {
		assets,
		account,
		options,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		saveCollection,
		fetchTags,
		fetchCollectionsList
	} )(CollectionsEdit)
);
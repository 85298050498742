import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import EmptySection from '../../components/chrome/empty';
import { clearSelection } from '../../actions/library';
import Loading from '../../components/chrome/loading';
import CampaignsToolbar from './toolbar';
import { fetchCampaignsList, saveCampaignItems, toggleCampaignItemHeadline, restrictCampaignItemHeadlinePromo } from '../../actions/campaigns';
import { saveAsset, fetchAssetsList } from '../../actions/assets';
import { fetchArticlesList } from '../../actions/articles';
import { fetchProductList } from '../../actions/products';
import CountUp from 'react-countup';
import InputField from '../../components/forms/field.js';

import { setSectionOptions } from '../../actions/chrome';
import _ from 'lodash';
import AssetGridItem from '../../components/assets/grid_item';
import DropzoneArea from '../../components/forms/dropzone';
//import { DragDropContext } from 'react-beautiful-dnd';

import { checkPermission } from '../../helpers/permissions';

class CampaignsAssets extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		
				
		this.state = {
			id: id,
			expanded: false,
			readonly: '',
			promo: {}
		}
		
		this.props.fetchCampaignsList(id, () => {
		       	
	       	if(!this.props.campaigns.campaigns[id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.props.fetchAssetsList();
       	this.props.fetchArticlesList();
       	this.props.fetchProductList();
       			
        this.updateDomElements();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	updateDomElements(){
				
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			let campaign = this.props.campaigns.campaigns[this.state.id];	
			
			this.props.setSectionOptions('campaigns', campaign.internal_name, `/campaigns/${this.state.id}`);
			let promo = {};
			
			if(campaign.items_restrictions && campaign.items_restrictions.headline && campaign.items_restrictions.headline.promo){
				promo = campaign.items_restrictions.headline.promo;
			}

			this.setState({
				readonly: campaign.status === 'draft' ? false : true,
				promo: promo
			});				
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:assets_title'), `/campaigns/${this.state.id}`);
		}
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
		
	openLibrary(){

		this.props.clearSelection(() => {
			this.props.history.push(`${this.props.location.pathname}/library/campaign/${this.state.id}`);
		});		
	}
	
	uploadFiles(files, headline, callback, callbackError){
					
		let values = {
			collection_id: false,
			files: files,
			source: 'local',
			headline: headline
		}
		
		this.props.saveAsset(false, false, values, (percent) => {
							
		}, (assets) => {
			
			let items = [];
			
			if(this.props.campaigns.campaigns[this.state.id].items.assets){
				
				_.forEach(this.props.campaigns.campaigns[this.state.id].items.assets, (asset, key) => {	
					items.push({ type: 'asset', id: asset });
	
				});
			}
			
			_.forEach(assets, (asset, key) => {	
				items.push({ type: 'asset', id: asset.id });

			});

			this.props.saveCampaignItems(false, this.state.id, items);
			callback();
		}, () => { callbackError() });
	}
	
	renderAssets(){
		
		if(!this.props.assets.assets || !this.props.articles.articles || !this.props.products.products || !this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id] || this.state.readonly === ''){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		let items_headline = [];
		let items_other = [];
		
		if(campaign.items){
									
			if(campaign.items.assets && !_.isEmpty(campaign.items.assets)){
				
				let item = false;
				
				_.forEach(campaign.items.assets, (id, key) => {	
					
					item = _.clone(this.props.assets.assets[id]);
					item.group = 'asset';
					
					if(campaign.items.headline.includes(item.id)){
						items_headline.push(item);
					}else{
						items_other.push(item);
					}
					
				}, this);
			}
			
			if(campaign.items.articles && !_.isEmpty(campaign.items.articles)){
				
				let item = false;
				
				_.forEach(campaign.items.articles, (id, key) => {	
					
					if(this.props.articles.articles && this.props.articles.articles[id]){
						item = _.clone(this.props.articles.articles[id]);
						item.type = 'article';
						items_other.push(item);	
					}				
				}, this);
			}
			
			if(campaign.items.products && !_.isEmpty(campaign.items.products)){
				
				let item = false;
				
				_.forEach(campaign.items.products, (id, key) => {	
					
					if(this.props.products.products && this.props.products.products[id]){
						item = _.clone(this.props.products.products[id]);
						item.type = 'product';
						items_other.push(item);	
					}				
				}, this);
			}
		}
		
		if(items_headline.length > 0 || items_other.length > 0){
			
			let headline_options = false;
			const description = JSON.parse(campaign.description)
						
			return (
				<div id="campaign_assets">
					<div className="hero c-card">
						<DropzoneArea
							type="multiple"
							processFiles={(files, callback, callbackError) => {
								this.uploadFiles(files, true, callback, callbackError);
							}}
							disabled={this.state.readonly ? true : false}
						>					
							<h4>
								{i18n.t(`campaigns:assets_headline_title`)}
								<span className="c-badge c-badge--small badge-light u-ml-xsmall">
									<CountUp 
										delay={0} 
										duration={1} 
										end={items_headline.length}
										separator="," 
										preserveValue={true}
									/>
								</span>
							</h4>
							<div className={`row assets`}>
						
								{items_headline.length === 0 && 
									<div className="col col-sm-12">
										{i18n.t(`campaigns:assets_headline_empty`)}
									</div>
								}
									
								{items_headline.length > 0 && 
									
									<React.Fragment>
										{_.map(items_headline, (item, key) => {
											
											item.headline = 1; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
											item.headlineSelection = 1;
											
											if(description.length > 0){
												
												let echo = i18n.t(`campaigns:assets_headline_restrict_none`);
												
												if(this.state.promo[item.id] && this.state.promo[item.id].length > 0){
													
													echo = (
														<ul>
															{_.map(this.state.promo[item.id], (promo, key) => {
																
																const max = 40;
																let label = description[key];
																
																return (
																	<li>
																		{label.length > max ? label.substr(0, max-1) + '...' : label}
																	</li>
																);
															})}
														</ul>
													);
												}														
				
												headline_options = (
													<InputField
														name={`promo_${item.id}`}
														placeholder={i18n.t(`campaigns:assets_headline_restrict_placeholder`)}
														className="u-mb-zero"
														type={!this.state.readonly ? 'suggest' : 'echo'}
														options={_.map(description, (promo, key) => {
															
															const max = 40;
															const preview = promo.length > max ? promo.substr(0, max-1) + '...' : promo;
															const label = i18n.t(`campaigns:assets_headline_restrict_label`, { counter: key+1 });
															
															return ({
																label: label,
																labelDropdown: `${label}: ${preview}`,
																value: key
															});
														})}
														selected={this.state.promo[item.id] ? this.state.promo[item.id] : false}
														echo={echo}
														multiple={true}
														onChangeFunc={(selected) => { 
															let promo = this.state.promo;
															promo[item.id] = selected;
																												
															this.setState({
																promo: promo
															});	
														}}
														onBlurFunc={() => {
																														
															const keys = _.map(this.state.promo[item.id], (item) => { return item.value });
												
															this.props.restrictCampaignItemHeadlinePromo(this.state.id, item.id, keys, () => {
																this.props.fetchCampaignsList(this.state.id);
															});															
														}}
														onKeyDown={(e) => {
															
															var code = (e.keyCode ? e.keyCode : e.which);
															
															if(code == 13) { //Enter keycode
															    e.target.blur();
															}
														}}
													/>
												);
											}
											
											return (
												<AssetGridItem 
													key={key}
													asset={item}
													size="large"
													actions={['edit']}
													headlineChange={() => {
														this.props.toggleCampaignItemHeadline(this.state.id, item.id, item.headline === 1 ? 0 : 1, () => {
															this.props.fetchCampaignsList(this.state.id);
														});
													}}
													sub={headline_options}
													animated={false}
													{...this.props}
												/>					
											);		
										})}
									</React.Fragment>
								}
							</div>
						</DropzoneArea>
					</div>
					<div className="other">
						<DropzoneArea
							type="multiple"
							processFiles={(files, callback, callbackError) => {
								this.uploadFiles(files, false, callback, callbackError);
							}}
							disabled={this.state.readonly ? true : false}
						>
							<div className="c-card grid-scroller" style={{ marginBottom: '0px' }}>
								<h4>
									{i18n.t(`campaigns:assets_other_title`)}
									<span className="c-badge c-badge--small badge-light u-ml-xsmall">
										<CountUp 
											delay={0} 
											duration={1} 
											end={items_other.length}
											separator="," 
											preserveValue={true}
										/>
									</span>
									
									{items_other.length > 0 && items_other.length > this.state.columns && 
										<div className="options">
											<i className={`fal fa-arrows-h ${this.state.expanded ? '' : 'selected'}`} onClick={() => { 
	
										 		this.setState({
											 		expanded: false
										 		});
										 		
											}}></i>
											<i className={`fal fa-th ${this.state.expanded ? 'selected' : ''}`} onClick={() => { 
				
										 		this.setState({
											 		expanded: true
										 		});
										 		
											}}></i>
										</div>
									}
								</h4>
								<div className={`row u-pb-zero assets ${this.state.expanded ? 'expanded' : ''}`}>
															
									{items_other.length === 0 && 
										<div className="col col-sm-12 u-pb-medium">
											{i18n.t(`campaigns:assets_other_empty`)}
										</div>
									}
										
									{items_other.length > 0 && 
										
										<React.Fragment>
											{_.map(items_other, (item, key) => {
												
												item.headline = 0; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
												item.headlineSelection = 1;
												
												return (
													<AssetGridItem 
														key={key}
														asset={item}
														size="small"
														actions={['edit']}
														headlineChange={() => {
															this.props.toggleCampaignItemHeadline(this.state.id, item.id, item.headline === 1 ? 0 : 1, () => {
																this.props.fetchCampaignsList(this.state.id);
															});
														}}
														animated={false}
														{...this.props}
													/>					
												);		
												
											})}
										</React.Fragment>
									}
								</div>
							</div>
						</DropzoneArea>
					</div>
				</div>
			);
			
		}else{
			
			let cta = false;
			
			if(!this.state.readonly){
				cta = {
					label: i18n.t('campaigns:assets_action_select'),
					onClick: this.openLibrary.bind(this)
				}
			}
			
			return (
				<DropzoneArea
					type="multiple"
					processFiles={(files, callback, callbackError) => {
						this.uploadFiles(files, false, callback, callbackError);
					}}
					disabled={this.state.readonly ? true : false}
				>
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('campaigns:assets_empty_title')}
						description={i18n.t('campaigns:assets_empty_description')}
						cta={cta}
					/>
				</DropzoneArea>
			);
		}
	}
	
	
	render() {
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		
		return (

			<React.Fragment>
				<CampaignsToolbar
					id={this.state.id}
					section="assets"
					readonly={this.state.readonly === '' ? false : this.state.readonly}
					continue={true}
					onClick={{
						select: this.openLibrary.bind(this)
					}}
					campaign={campaign}
					{...this.props}
				/>
				
				<div className="container">
					{this.renderAssets()}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ campaigns, assets, account, articles, products }, ownProps){
	return {
		campaigns,
		assets,
		account,
		articles,
		products
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCampaignsList,
	clearSelection,
	fetchAssetsList,
	saveAsset,
	saveCampaignItems,
	fetchArticlesList,
	fetchProductList,
	toggleCampaignItemHeadline,
	restrictCampaignItemHeadlinePromo
})(CampaignsAssets);
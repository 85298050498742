import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import UsersReducer from './users';
import ErrorsReducer from './errors';
import OverlayReducer from './overlay';
import AccountReducer from './account';
import TrackingReducer from './tracking';
import CampaignsReducer from './campaigns';
import AssetsReducer from './assets';
import TemplatesReducer from './templates';
import SystemReducer from './system';
import MessagesReducer from './messages';
import LibraryReducer from './library';
import FormsReducer from './forms';
import ChromeReducer from './chrome';
import StatsReducer from './stats';
import OptionsReducer from './options';
import BrandReducer from './brand';
import SubscribersReducer from './subscribers';
import ArticlesReducer from './articles';
import ProductsReducer from './products';
import ConnectionsReducer from './connections';
import RetailersReducer from './retailers';
import RegionalReducer from './regional';
import { reducer as notificationsReducer } from 'reapop';

import { userConstants } from '../constants/users';

const appReducer = combineReducers({
	form: formReducer, 
	overlay: OverlayReducer,
	account: AccountReducer,
	users: UsersReducer,
	assets: AssetsReducer,
	campaigns: CampaignsReducer,
	errors: ErrorsReducer,
	messages: MessagesReducer,
	tracking: TrackingReducer,
	library: LibraryReducer,
	templates: TemplatesReducer,
	forms: FormsReducer,
	chrome: ChromeReducer,
	options: OptionsReducer,
	brand: BrandReducer,
	articles: ArticlesReducer,
	system: SystemReducer,
	products: ProductsReducer,
	retailers: RetailersReducer,
	regional: RegionalReducer,
	subscribers: SubscribersReducer, 
	connections: ConnectionsReducer, 
	stats: StatsReducer,
	notifications: notificationsReducer()
});

const rootReducer = (state, action) => {
	
	if(action.type === userConstants.LOGOUT){
		state = undefined;
	}
	
	return appReducer(state, action)
}

export default rootReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import InputField from '../../components/forms/field.js';
import CountUp from 'react-countup';

import AssetGridItem from '../../components/assets/grid_item';
import DropzoneArea from '../../components/forms/dropzone';
import { fetchTags } from '../../actions/options';
import { imageResize } from '../../helpers/s3';
import { forceDownload } from '../../helpers/download'; 

import _ from 'lodash';
import i18n from '../../helpers/i18n'; 

import { fetchCollectionsList, fetchAssetsList, saveAsset, downloadAsset } from '../../actions/assets';
import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';

class Assets extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'ASSETS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions('assets', i18n.t('assets:title'));
		this.props.fetchCollectionsList();
		this.props.fetchAssetsList();
		this.props.fetchTags();
		
		this.state = {
			keyword: '',
			expanded: {
				collection: false, 
				image: false,
				video: false,
				document: false
			},
			selected: []
		}
		
		this.getKeywordBouncer = _.debounce((keyword) => {
			
			this.setState({
				keyword: keyword
			})
		}, 250);
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	toggleScroller(type){
				 			 		
 		return (
	 		<div className="options">
				<i className={`fal fa-arrows-h ${this.state.expanded[type] ? '' : 'selected'}`} onClick={() => { 
					
					let expanded = this.state.expanded;
			 		expanded[type] = false;
			 
			 		this.setState({
				 		expanded
			 		});
			 		
				}}></i>
				<i className={`fal fa-th ${this.state.expanded[type] ? 'selected' : ''}`} onClick={() => { 
					
					let expanded = this.state.expanded;
			 		expanded[type] = true;
			 
			 		this.setState({
				 		expanded
			 		});
			 		
				}}></i>
			</div>
 		)	
	}
	
	onSelectItem(id){
		
		let selected = this.state.selected;
		
		if(!selected.includes(id)){
				selected.push(id);
		}else{
			
			let index = selected.indexOf(id);
 
		    if(index > -1) {
		    	selected.splice(index, 1);
		    }
		}
		
		this.setState({
			selected: selected
		})
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 4;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	cleanTags(type, tags){
		
		let ret = [];
																	
		_.forEach(tags, (tag, key) => {	
			
			if(this.props.options.tags[type] && this.props.options.tags[type][key]){
				tag = this.props.options.tags[type][key];
				ret.push(i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`));
			}else{
				ret.push(tag);
			}
		});
			
		return ret;
	}
	
	renderCollections(){
		
		let collections = [];
		let assets = [];
		let url = '';
		
		_.forEach(this.props.assets.collections, (collection, key) => {					
									
			assets = [];
			
			_.forEach(this.props.assets.assets, (asset, key) => {					
										
				if(collection.assets.includes(asset.id)){
					
					if(this.state.keyword){
						
						if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
							assets.push(asset);
						}else if(asset.tags){
							
							if(_.filter(this.cleanTags('asset', asset.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
								assets.push(asset);
								return;
							}
						}
						
					}else{
						assets.push(asset);
					}
				}
			});
			
			collection.counter = assets.length;
			
			if(this.state.keyword){
								
				if(collection.counter > 0){
					collections.push(collection);
				}else{
					
					if(collection.name !== '' && collection.name !== null && collection.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
						collections.push(collection);
					}else if(collection.tags){
						
						if(_.filter(this.cleanTags('collection', collection.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
							collections.push(collection);
							return;
						}
					}
				}
				
			}else{
				collections.push(collection);
			}

		}, this);
					
		collections = _.orderBy(collections, ['created.date'],['desc']); 
		
		if(this.state.keyword){
			url = `?keyword=${encodeURI(this.state.keyword)}`;
		}

		return (
			<div className="c-card grid-scroller">
				<h4>
					{i18n.t('assets:title_collections')}
					<span className="c-badge c-badge--small badge-light u-ml-xsmall">
						<CountUp 
							delay={0} 
							duration={1} 
							end={_.isEmpty(collections) ? 0 : collections.length}
							separator="," 
							preserveValue={true}
						/>
					</span>

					{collections.length > this.state.columns && 
	         			this.toggleScroller('collection')
	         		}
				</h4>
				
				{_.isEmpty(collections) &&
					<div className="u-pb-medium">
						{i18n.t(`assets:collections_empty_title`)}
					</div>
				}
				
				{!_.isEmpty(collections) &&
					
					<div className={`row u-pb-zero collections ${this.state.expanded.collection ? 'expanded' : ''}`}> 
				   
					    {_.map(collections, (collection, key) => {
						    
						    let locked = '';
		
							if(collection.readonly === 1){
								locked = (
									<i className="far fa-lock locked"></i>
								);
							}
											
							return (
								<div className="scroller-col col-sm-6 col-lg-4 col-xl-3" key={key}>
									<DropzoneArea
										type="multiple"
										processFiles={(files, callback, callbackError) => {
											
											let values = {
												collection_id: collection.id,
												files: files,
												source: 'local'
											}
											
											this.props.saveAsset(false, false, values, (percent) => {
							
											}, callback, callbackError);
										}}
										border={true}
										disabled={collection.readonly === 1 ? true : false}
									>
										<Link to={`/assets/collection/${collection.id}${url}`} className="c-card is-animated u-text-center equalize">
											<div className="u-mb-small directory">
												<i className="fal fa-folder"></i>
												{locked}
											</div>
											<div className="info">
												<h4>{collection.name}</h4>
												<p>
													<CountUp 
														delay={0} 
														duration={1} 
														end={collection.counter}
														separator="," 
														preserveValue={true}
													/> {this.state.keyword ? 'Matching ' : ''}{collection.counter === 1 ? 'Asset' : 'Assets'}
												</p>
											</div>
										</Link>					
									</DropzoneArea>					
					            </div>
							);
						})}
					</div>
				}
			</div>
		);	
	}
	
	renderFiles(){
				
		let assets = {
			image: [],
			video: [],
			document: []
		}
				
		_.forEach(this.props.assets.assets, (asset, key) => {	
			
			let type = asset.type;
			
			switch(type){
				
				case 'youtube':
				case 'vimeo':
					type = 'video';	
					break;
					
				case 'pdf':
				case 'document':
				case 'presentation':
				case 'spreadsheet':
					type = 'document';	
					break;
					
				default:
				
					break;
			} 				
									
			if(assets[type]){
				
				if(this.state.keyword){

					if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
						assets[type].push(asset);
					}else if(asset.tags){
						
						if(_.filter(this.cleanTags('asset', asset.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
							assets[type].push(asset);
							return;
						}
					}
					
				}else{
					assets[type].push(asset);
				}
			}
		});
						
		return _.map(assets, (type_assets, key) => {
			
			type_assets = _.orderBy(type_assets, ['created.date'],['desc']); 
				
			return (
	         	<div className="c-card grid-scroller" key={key}>
		         	<h4>
		         		{i18n.t(`assets:type_title`, { type: key })}
		         		<span className="c-badge c-badge--small badge-light u-ml-xsmall">
		         			<CountUp 
								delay={0} 
								duration={1} 
								end={_.isEmpty(type_assets) ? 0 : type_assets.length}
								separator="," 
								preserveValue={true}
							/>
						</span>
		         		
		         		{type_assets.length > this.state.columns && 
		         			this.toggleScroller(key)
		         		}
		         	</h4>
		         	
		         	{_.isEmpty(type_assets) && 
			         	<div className="u-pb-medium">
			         		{i18n.t(`assets:type_empty_description`, { type: key })}
			         	</div>
		         	}
		         	
		         	{!_.isEmpty(type_assets) && 
			         	<div className={`row u-pb-zero assets ${this.state.expanded[key] ? 'expanded' : ''}`}>
							{_.map(type_assets, (asset, key2) => {	
								
								let selected = this.state.selected.includes(asset.id) ? true : false;
								let onSelect = 'disabled';
										
								if(asset.type !== 'youtube' && asset.type !== 'vimeo'){
									onSelect = this.onSelectItem.bind(this);
								}
								
								return (
									<AssetGridItem 
										key={`${key2}_${key}`}
										asset={asset}
										onSelectItem={onSelect}
										selected={selected}
										icon={selected ? 'fa-minus' : 'fa-plus'}
									/>						
								);
							})}
						</div>			         	
		         	}		         	
				</div>
			);
		});
		
	}

	render() {

		let { assets } = this.props;

		if(!assets.assets || !assets.collections){
			return (
				<Loading />
			);
		}
		
		let buttons = [
			{
				icon: 'fa-download',
				onClick: () => {
					this.props.downloadAsset(this.state.selected.join(','), (url, filename) => {
						forceDownload(url, filename);
					});				
			},
				outline: true
			},
			{
				label: i18n.t('assets:action_add_collection'),
				url: `${this.props.location.pathname}/collection/add`
			}
		];
		
		if(this.state.selected.length === 0){
			buttons.shift();
		}
		
		const background = imageResize(`brands/${this.props.brand.details.directory}/heroes/assets.jpg`, 1500);

		return (
			<React.Fragment>

				<div className="jumbotron" style={{ backgroundImage: `url(${background})`}}>
					<div className="container">
						<div className="contents">
							<h2>{i18n.t('assets:hero_title')}</h2>
							<p>{i18n.t('assets:hero_description')}</p>
							
							<InputField
								name="keyword"
								type="text"
								placeholder={i18n.t('assets:hero_keyword')}
								onChangeFunc={(event) => {
									this.getKeywordBouncer(event.target.value === '' ? false : event.target.value);									
								}}
							/>
						</div>
					</div>
				</div>

				<Toolbar
					buttons={buttons}
				/>
										
				<div className="container">
					{this.renderCollections()}
					{this.renderFiles()}	
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, account, brand, options }, ownProps){
	return {
		assets,
		account,
		brand,
		options
	};
}

export default connect(mapStateToProps, { 
	fetchCollectionsList, 
	fetchAssetsList,
	setSectionOptions,
	saveAsset,
	downloadAsset,
	fetchTags
})(Assets);
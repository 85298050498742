import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import Loading from '../../../components/chrome/loading';
import Toolbar from '../../../components/chrome/toolbar';
import { startSubmission, stopSubmission } from '../../../actions/forms';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';

var formName = 'formRetailersIntegrationsMailchimp';


class RetailersIntegrationsMailchimp extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.state = {
	       	id: id,
	       	key: false
       	}
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];
			let init = {};
						
			if(retailer.details.mailchimp_key){
				init.key = retailer.details.mailchimp_key;
				
				this.setState({
					key: retailer.details.mailchimp_key
				});	
			}
			
			if(retailer.details.mailchimp_email){
				init.email = retailer.details.mailchimp_email;
			}
			
			this.props.initialize(init);	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
		  	
	  	this.props.saveRetailerIntegration(formName, 'mailchimp', { key: values.key, email: values.email }, () => {
			
			if(this.state.key && values.key === ''){
				
				this.props.showNotification(
					'success',
					i18n.t('retailers:notification_integration_mailchimp_disconnected_title'),
					i18n.t('retailers:notification_integration_mailchimp_disconnected_description')
				);
				
			}else{
				
				this.props.showNotification(
					'success',
					i18n.t('retailers:notification_integration_mailchimp_connected_title'),
					i18n.t('retailers:notification_integration_mailchimp_connected_description')
				);
			}
			
			this.props.history.push(this.props.parentPath);
	  	});
	}

	render() {
			
		const { handleSubmit } = this.props;
		const { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const retailer = retailers.retailers[this.state.id];
				
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_mailchimp_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:integration_mailchimp_toolbar_settings'),
						url: `${this.props.parentPath}/integrations/email`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('retailers:integration_mailchimp_action_save'),
						submit: formName
					}]}
				/>
				
				<div className="row">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_mailchimp_settings_title')}</h4>
							
							<Field
								label={i18n.t('retailers:integration_mailchimp_settings_label_key')}
								name="key"
								type="text"
								component={InputField}
							/>
							
							<Field
								label={i18n.t('retailers:integration_mailchimp_settings_label_email')}
								labelSub={i18n.t('retailers:integration_mailchimp_settings_label_email_info', {email: retailer.details.email})}
								name="email"
								type="text"
								component={InputField}
							/>
						</div>
						
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_mailchimp_instructions_title')}</h4>
							
							<ol className="numbererd">
								<li>Log in to your <a href="https://www.mailchimp.com" target="_blank" rel="noopener noreferrer">MailChimp account</a>.</li>
								<li>Click on your Name in the top right corner.</li>
								<li>Click on Account.</li>
								<li>On the row of tabs you will see an option called "Extras" - click on its sub option called "API Keys".</li>
								<li>Create a new API key.</li>
							</ol>
						</div>
						
					</div>					
				</div>
			</form>
		);
		
	}
}

const validate = (values) => {	
	
	const errors = {};

	
	return errors;
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		saveRetailerIntegration,
		showNotification,
		fetchRetailersList
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsMailchimp)
);

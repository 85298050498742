import { subscribersConstants } from '../constants/subscribers';
import _ from 'lodash';

export default function(state = { groups: {}, history: {} }, action) {
	
	switch (action.type) {
		
		case subscribersConstants.SUBSCRIBERS_GROUPS:
					
			let groups = state.groups;
			
			_.forEach(action.payload.data, (data, key) => {					
									
				if(groups[key]){
					groups[key] =  { ...groups[key], ..._.mapKeys(data, 'id') }
				}else{
					groups[key] = _.mapKeys(data, 'id');
				}
			});
			
			return { 
				...state, 
				groups: groups
			}
			
		case subscribersConstants.SUBSCRIBERS_USERS:
					
			return { 
				...state, 
				users: { ...state.users, ..._.mapKeys(action.payload.data, 'id') }
			}
		
		case subscribersConstants.SUBSCRIBERS_STATS:
					
			return { 
				...state, 
				overview: action.payload.data
			}
			
		case subscribersConstants.SUBSCRIBERS_HISTORY:
					
			let history = state.history;

			history[action.user] = action.payload.data;
			
			return { 
				...state, 
				history: history
			}			
		
		case subscribersConstants.SUBSCRIBERS_UNSUBSCRIBE:
					
			let users = state.users;

			if(users[action.user]){
				delete(users[action.user])
			}
			
			return { 
				...state, 
				users: users
			}
				
		default:
			return state;
	}
}